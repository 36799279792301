import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import Autocomplete from "@mui/material/Autocomplete";
import IMAGES from "../../../Middleware/images";
import ERR_MESSAGE from "../../../Helper/error-helper";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import apiHelper from "../../../Helper/api-helper";
import CONFIG from "../../../Helper/config";
import ROUTES from "../../../Middleware/routes";
import { ToastContainer, toast } from 'react-toastify';
import ReactLoader from "../../../ControlComponents/react-loader";

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const RegisterEmployee = (props) => {

    const navigate = useNavigate();
    const inputRef = useRef();
    let key = "AIzaSyDFkw6-U8mAQEGTOC77ZYrIojD4isGoNgg";
    const [searchParams, setSearchParams] = useSearchParams();

    const [eye_icon, setEye_icon] = useState(IMAGES.EYE_HIDE);
    const [type, setType] = useState("password");
    const [msg, setMsg] = useState("reveal password");

    const { state } = useLocation();
    const [fields, setFields] = useState({
        userName: "",
        email: "",
        role: "",
        password: "",
        place: "",
        latitude: "",
        longitude: "",
        experience: "",
        subexpArr: [],
        subsplArr: [],
        interests: "",
        rolesArr: [],
        tap: "",
        tapArr: [],
        speciality: "",
        experiencesArr: [],
        specialityArr: [],
        interestsArr: [],
        rolesArr: [],
        type: ""
    });

    const [errors, setErrors] = useState("");

    const [isLoading, setLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);

    const [roleList, setRoleList] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState(roleList);

    const [openDropdown, setOpenDropdown] = useState(false);

    const closeDropdown = () => {
        setOpenDropdown(false);
    };

    const handleInputChange = (event, newValue) => {
        setErrors("");
        const filteredRoles = roleList?.filter(role => role.toLowerCase().includes(newValue.toLowerCase()));
        setFilteredOptions(filteredRoles);
        setFields(prevFields => ({
            ...prevFields,
            role: newValue
        }));
    };

    const handleSelectionChange = (event, newValue) => {
        setErrors("")
        if (event.key === 'Enter' || event.code === 'NumpadEnter') {
            setOpenDropdown(false);
        }
        setFields(prevFields => ({
            ...prevFields,
            role: newValue,
        }));
        setOpenDropdown(false);
    };

    const handleChange = (e) => {
        setErrors("");
        const name = e.target.name;
        const value = e.target.value;
        setFields({
            ...fields,
            [name]: value,
        });
        if (name === "place") {
            setFields({
                ...fields,
                [name]: value,
                latitude: "",
                longitude: ""
            });
        }

    };

    const handlePlaceChanged = () => {
        const [place] = inputRef?.current?.getPlaces();

        if (place) {
            setFields({
                ...fields,
                place: place.formatted_address,
                latitude: `${place.geometry.location.lat()}`,
                longitude: `${place.geometry.location.lng()}`,
            });
        }
    };

    const handleChange2 = (e) => {
        setErrors("");
        const name = e.target.name;
        const value = e.target.value;
        setFields(prevFields => ({
            ...prevFields,
            [name]: value
        }));
        if (e.target.value === "" || roleList.includes(value)) {
            setOpenDropdown(false)
        } else {
            setOpenDropdown(true);
        }

    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        let passwordReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
        const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
        const {
            role,
            email,
            userName,
            place,
            longitude,
            latitude,
        } = fields;
        if (!userName || userName.trim().length === 0) {
            formIsValid = false;
            errors["userName"] = ERR_MESSAGE.EMPTY_FULLNAME;
        } else if (userName.length < 3) {
            formIsValid = false;
            errors["userName"] = ERR_MESSAGE.INVALID_FULLNAME;
        }
        else if (userName.length > 30) {
            formIsValid = false;
            errors["userName"] = ERR_MESSAGE.INVALID_FULLNAME_MAX;
        }
        else if (emojiRegex.test(userName)) {
            formIsValid = false;
            errors["userName"] = ERR_MESSAGE.EMOJI;
        }
        else if (userName.length > 30) {
            formIsValid = false;
            errors["userName"] = ERR_MESSAGE.INVALID_FULLNAME1;
        }

        if (!email || email.trim().length === 0) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_EMPTY;
        } else if (reg.test(email.toLowerCase()) === false) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
        }
        if (!place || place.trim().length === 0) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.LOCATION_EMPTY;
        }

        else if (!longitude || longitude.trim().length === 0 || !latitude || latitude.trim().length === 0) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.INVALID_LOCATION;
        }
        else if (emojiRegex.test(place)) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.EMOJI;
        }

        if (!role || role.trim().length === 0) {
            formIsValid = false;
            errors["role"] = ERR_MESSAGE.ROLE_EMPTY;
        }
        else if (emojiRegex.test(role)) {
            formIsValid = false;
            errors["role"] = ERR_MESSAGE.EMOJI;
        }

        setErrors(errors);
        return formIsValid;
    };
    const error = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }
    const show = () => {
        if (type === "password") {
            setType("text");
            setEye_icon(IMAGES.EYE_SHOW);
            setMsg("hide password");
        } else {
            setType("password");
            setEye_icon(IMAGES.EYE_HIDE);
        }
    };

    const organisation_id = localStorage.getItem("id")
    async function postAPI(action) {

        let data = {
            name: fields.userName,
            email: fields.email,
            designation: fields.role,
            organisation_id: state?.id ? state?.id : organisation_id,
            location: fields.place,
            latitude: fields.latitude,
            longitude: fields.longitude,
            type: fields.type,
            action: action
        };

        setLoading(true);
        let result = await apiHelper.postRequest("organization/create-user", JSON.stringify(data));
        console.log("org create user", )
        if (result?.code === CONFIG.statusCode) {
            if (action == "invite") {
                props.setInviteModel(true);

            }
            else {
                props.setMemberAddedModel(true);
            }

            setLoading(false);
            props.setRegisterModel(false)
        } else {
            error(result.message)
            setLoading(false);
        }
    }

    async function getRoleAPI() {
        setLoading(true);
        let result = await apiHelper.getRequest(`organization/role-list`);
        console.log("this is reesult", result);
        if (result?.code === CONFIG.statusCode) {
            setRoleList(result?.body);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        getRoleAPI()
        console.log('first $$$')
    }, []);


    return <>
        <div className='loader'>
            {isLoading && <ReactLoader />}
        </div>
        <div className="col-lg-10 mx-auto">
            <div className="text-start position-relative">
                <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    sx={{
                        "& fieldset": {
                            border: "2px solid #231f20",
                            borderRadius: "30px",
                        },
                        "& .MuiInputLabel-shrink": {
                            color: "#231f20;",
                        },
                    }}
                    name="userName"
                    onChange={handleChange}
                    value={fields?.userName?.replace(/-/g, ' ')}
                />

                {errors?.userName && <span className="err_msg">{errors.userName}</span>}
            </div>
            <div className="my-4 text-end">
                <TextField
                    fullWidth
                    label="Email address"
                    variant="outlined"
                    sx={{
                        "& fieldset": {
                            border: "2px solid #231f20",
                            borderRadius: "30px",
                        },
                        "& .MuiInputLabel-shrink": {
                            color: "#231f20;",
                        },
                    }}
                    name="email"
                    onChange={handleChange}
                    value={searchParams.get("email")}
                />
                {errors.email &&
                    <span className="err_msg">{errors.email}</span>}
            </div>

            <div className="my-4 text-end">
                <LoadScript
                    googleMapsApiKey={key}
                    libraries={["places"]}
                >
                    <StandaloneSearchBox
                        onLoad={(ref) => (inputRef.current = ref)}
                        onPlacesChanged={handlePlaceChanged}
                    >
                        <TextField
                            fullWidth
                            label="Location"
                            variant="outlined"
                            sx={{
                                "& fieldset": {
                                    border: "2px solid #231f20",
                                    borderRadius: "30px",
                                },
                                "& .MuiInputLabel-shrink": {
                                    color: "#231f20;",
                                },
                            }}
                            name="place"
                            onChange={handleChange}
                            value={fields.place}
                            placeholder=""
                        />
                    </StandaloneSearchBox>
                    {errors?.place && <span className="err_msg">{errors.place}</span>}
                </LoadScript>
            </div>
            <div className="my-4 text-end role-custome">
                <Autocomplete
                    fullWidth
                    options={filteredOptions}
                    onBlur={closeDropdown}
                    getOptionLabel={(option) => option}
                    value={fields?.role}
                    onInputChange={handleInputChange}
                    onChange={handleSelectionChange}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" || e.code === "NumpadEnter") {
                            setOpenDropdown(false);
                        }
                    }}
                    open={openDropdown}
                    disableClearable
                    freeSolo
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            onChange={(e) => handleChange2(e)}
                            label="Role/Title"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment></React.Fragment>
                                ),
                            }}
                            onFocusCapture={() => {
                                setOpenDropdown(false);
                            }}
                            sx={{
                                "& fieldset": {
                                    border: "2px solid #231f20",
                                    borderRadius: "30px",
                                },
                                "& .MuiInputLabel-shrink": {
                                    color: "#231f20;",
                                },
                            }}
                        />
                    )}
                />

                {errors?.role && <span className="err_msg">{errors.role}</span>}
            </div>

            <div className="my-4 text-end">


                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                    <Select fullWidth
                        value={fields?.type?.replace(/-/g, ' ')}
                        name="type"
                        label="Type"
                        variant="outlined"
                        onChange={handleChange}
                        sx={{
                            "& fieldset": {
                                border: "2px solid #231f20",
                                borderRadius: "30px",
                            },
                            "& .MuiInputLabel-shrink": {
                                color: "#231f20;",
                            },
                        }}
                    >
                        <MenuItem value="2">Organization Admin</MenuItem>
                        <MenuItem value="1">Member</MenuItem>
                    </Select>
                </FormControl>
                {errors.email &&
                    <span className="err_msg">{errors.email}</span>}
            </div>

            <div className="text-center position-relative submit-head d-flex align-items-center gap-4 step-one-bottom justify-content-center mt-5">

                <button
                    type="submit"
                    className="proceed-yellow-btn position-relative d-flex align-items-center mb-5"
                    onClick={(e) => {
                        e.preventDefault();
                        if (handleValidation()) {
                            postAPI("invite")
                        }

                    }}
                    disabled={isLoading}
                >
                    Send Invite{" "}
                    <img
                        src={IMAGES.ARROW_GREY}
                        alt=""
                        className="img-fluid ms-2"
                    />
                </button>

                <button
                    type="submit"
                    className="proceed-yellow-btn position-relative d-flex align-items-center mb-5"
                    style={{ background: "#eb62d4" }}
                    disabled={isLoading}
                    onClick={(e) => {
                        e.preventDefault();
                        postAPI("save")
                        // if (handleValidation()) {
                        //     postAPI("save")
                        // }

                    }}
                >
                    Save{" "}
                    <img
                        src={IMAGES.ARROW_GREY}
                        alt=""
                        className="img-fluid ms-2"
                    />
                </button>
            </div>
        </div>
    </>
}

export default RegisterEmployee