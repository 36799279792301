import React, { useState } from 'react'
import IMAGES from '../Middleware/images'
import apiHelper from '../Helper/api-helper';
import ERR_MESSAGE from '../Helper/error-helper';
import CONFIG from "../Helper/config"
import { useNavigate } from 'react-router-dom';
import ROUTES from '../Middleware/routes';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import titles from '../Helper/title';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '@mui/material/Modal';
import ReactLoader from '../ControlComponents/react-loader';
import "../Assets/Styles/sign-in.css"
import TextField from '@mui/material/TextField';

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 676,
    // bgcolor: 'background.paper',
    // borderRadius: "16px",
    // boxShadow: 24,
    // border: "1px solid #6ED7EF",
    // p: 0,
    // outline: 0,
    // textAlign: "center",
};
export const SignIn = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setFields(
            {
                ...fields,
                email2: ""
            }
        )
        setOpen(false);
    }
    const [isLoading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()
    const [errors, setErrors] = useState("");
    const [fields, setFields] = useState({
        email: "",
        password: "",
        email2: "",
    });
    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name;
        const value = e.target.value;
        setFields({
            ...fields,
            [name]: value,

        });

    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };


    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        const { email, password } = fields;
        if (!email || email.trim().length === 0) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_EMPTY
        }
        else if (reg.test(email.toLowerCase()) === false) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID
        }
        if (!password || password.trim().length === 0) {
            formIsValid = false;
            errors["password"] = ERR_MESSAGE.PASSWORD_EMPTY
        }
        setErrors(errors);
        return formIsValid;
    };

    const handleValidation2 = () => {
        let errors = {};
        let formIsValid = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        const {email2} = fields;
        if (!email2 || email2.trim().length === 0) {
            formIsValid = false;
            errors["email2"] = ERR_MESSAGE.EMAIL_EMPTY
        }
        else if (reg.test(email2.toLowerCase()) === false) {
            formIsValid = false;
            errors["email2"] = ERR_MESSAGE.EMAIL_INVALID
        }
        setErrors(errors);
        return formIsValid;
    };

    const errorLogin = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,

            });
    }

    async function postAPI(e) {

        e.preventDefault();
        if (handleValidation()) {
            let data = JSON.stringify({
                "email": fields.email,
                "password": fields.password
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/login", data)

            console.log("this is results", result);
            if (result?.code === CONFIG.statusCode) {
                localStorage.setItem("token", result?.body?.token);
                localStorage.setItem("role", result?.body?.user?.role);
                localStorage.setItem("id", result?.body?.user?.id);

                if(result.body.user.role ==1){
                        navigate(ROUTES.SEARCH_DATABASE)
                }
                else if(result.body.user.role ==2){
                    navigate(ROUTES.SEARCH_EMPLOYEES)
                }
                else if(result.body.user.role ==3){
                    navigate(ROUTES.ORGANIZATION_LIST)
                }
                 setLoading(false);
            }

            else {
                errorLogin(result.message);
                setLoading(false)
            }
        }
    }
    const success = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }

    async function postAPIForgotPassword(e) {
        e.preventDefault();
        if (handleValidation2()) {
            let data = JSON.stringify({
                "email": fields.email2
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/forgot-passsword", data)
            if (result?.code === CONFIG.statusCode) {
                success(result.message)
                setLoading(false);
                setOpen(false);
            } else {
                errorLogin(result.message);
                setLoading(false);
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>{titles.signIn}</title>
            </Helmet>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <section className="signin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-6 p-0 signin-img">
                            <div className="signin-img position-relative">
                                {/* <img src={IMAGES.NEW_LOGIN_IMG} alt="img-fluid" className="cus-img-fluid" /> */}
                                    <div className='logo-sign'>
                                       <img src={IMAGES.NEW_LOGO_IMG} alt="img-fluid"  className='logo-sign-inner' />
                                     </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 p-0">
                            <div className="signin-main">
                                <div className="signin-inner">
                                    <div className="signin-logo text-center pb-4">
                                        <img src={IMAGES.HAPTAPT_LOGO} alt="signin-image" className="img-fluid" />

                                    </div>
                                    <div className="signin-head text-center pb-3">
                                        <h1>Sign in</h1>
                                    </div>
                                    <div className="signin-form text-center">
                                        <form onSubmit={postAPI}>
                                            <div className="mb-4 text-start position-relative">
                                                 <TextField fullWidth label="Email address" variant="outlined"
                           sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                            }}
                        name="email" onChange={handleChange}  value={fields.email.trim()} />
                                              { errors?.email &&  <span className="err_msg">{errors.email}</span>}
                                            </div>
                                            <div className="mb-2 mb-lg-4 text-end position-relative forgot-input">
                                            <TextField type={showPassword ? "text":"password"} fullWidth label="Enter password" variant="outlined"
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                        }}
                    name="password" onChange={handleChange}  value={fields.password.trim()} />

                                                <img src={showPassword? IMAGES.EYE_SHOW : IMAGES.EYE_HIDE} onClick={togglePasswordVisibility} style={{ cursor: "pointer" }} className="hiden-eye" />

                                              { errors?.password && <span className="err_msg">{errors.password}</span>}
                                                <a className="forgot-link mt-2" onClick={handleOpen}>Forgot Password?</a>
                                            </div>

                                            <div className="text-center position-relative submit-head">
                                                <button type="submit" className="yellow-btn reset-btn d-flex align-items-center">Let’s Tap <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal modal-custom">
                <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                <div className="modal-content modal-content-custom">
                    <div onClick={handleClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                        <img src={IMAGES.CROSS} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2 className='p-0' style={{ lineHeight: "1" }}>Reset password</h2>
                    </div>
                    <div className="position-relative px-3 text-center">
                        <p>
                            We will send a password reset link to your registered email address.
                        </p>
                    </div>
                    <form onSubmit={postAPIForgotPassword}>
                        <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
                        <TextField fullWidth label="Email address" variant="outlined"
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                        }}
                    name="email2" onChange={handleChange}  value={fields.email2.trim()} />
                            { errors?.email2 &&<span className="err_msg">{errors.email2}</span>}
                        </div>
                        <div className="mb-5 text-center position-relative submit-head">
                            <button type="submit" className="yellow-btn d-flex align-items-center">Request reset link <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                        </div>
                    </form>
                    </div>
                    </div>
                </Box>
            </Modal>
            <ToastContainer rtl/>
        </>
    )
}
