import { useState, useRef } from "react";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";

import { TextField, Stack, Typography } from "@mui/material";
import { IMAGES_INFO } from "../Middleware/images";
import HaptaptImages  from "./ui/HaptaptImages";
import EmailIcon from "@mui/icons-material/Email";
import { handleValidation } from "../utils";
import HaptaptModal from "./ui/HaptaptModal";
import SaveIcon from "@mui/icons-material/Save";
import InputAdornment from "@mui/material/InputAdornment";
// date picker
import ReactLoader from "../ControlComponents/react-loader";

import apiHelper from "../Helper/api-helper";
import CONFIG from "../Helper/config";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const initOrgValues = {
  userName: "",
  email: "",
  start_date: "",
  end_date: "",
  total_user_allow: "",
  price: "",
  location: "",
  latitude: "",
  longitude: "",
};

const AddNewOrganizationModal = ({
  isNewOrgModalOpen,
  handleCloseNewOrgModal,
  getOrgList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgInfo, setOrgInfo] = useState(initOrgValues);
  const inputRef = useRef();

  const [errors, setErrors] = useState({});

  let key = "AIzaSyDFkw6-U8mAQEGTOC77ZYrIojD4isGoNgg";

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setErrors({});
    setOrgInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef?.current?.getPlaces();
    setErrors({});
    if (!place) return;
    console.log("$$$ place", place);
    setOrgInfo((prev) => ({
      ...prev,
      location: place.formatted_address,
      latitude: `${place.geometry.location.lat()}`,
      longitude: `${place.geometry.location.lng()}`,
    }));
  };

  const handleStartDate = (date) => {
    const dateTransformed = dayjs(date)?.format("YYYY-MM-DD");
    const isDateInvalid = !!dateTransformed === "Invalid Date";
    setOrgInfo((prev) => ({
      ...prev,
      start_date: isDateInvalid ? "" : dateTransformed,
    }));
  };
  const handleEndDate = (date) => {
    const dateTransformed = dayjs(date)?.format("YYYY-MM-DD");
    const isDateInvalid = !!dateTransformed === "Invalid Date";
    setOrgInfo((prev) => ({
      ...prev,
      end_date: isDateInvalid ? "" : dateTransformed,
    }));
  };

  const handleSubmitOrg = async (action) => {
    const isOrgInfoValid = handleValidation({
      fields: orgInfo,
      setErrors: setErrors,
    });
    if (!isOrgInfoValid) return;

    console.log("submitting info $$$", orgInfo);

    const submitOrgInfo = {
      ...orgInfo,
      action,
    };

    setIsLoading(true);

    try {
      const result = await apiHelper.updatedPostRequest(
        "organization/create-organization",
        submitOrgInfo
      );

      if (result.code === CONFIG.statusCode) {
        setIsLoading((prev) => !prev);
        setOrgInfo(initOrgValues);
        handleCloseNewOrgModal();
        getOrgList();
      }
    } catch(err) {
      setErrors((prev) => ({
        ...prev,
        page: err?.message,
      }));
      setIsLoading(false);
    }
  };

  return (
    <HaptaptModal
      isOpen={isNewOrgModalOpen}
      handleClose={handleCloseNewOrgModal}
      keepMounted
    >
      <section
        onClick={handleCloseNewOrgModal}
        className=" position-relative mb-0 text-end pt-3 pe-3"
        style={{ cursor: "pointer" }}
      >
        <HaptaptImages
          src={IMAGES_INFO.CROSS}
          alt="cross"
        />
      </section>
      <Stack spacing={1}>
        <Typography
          variant="h4"
          sx={{ textAlign: "center" }}
        >
          Add New Organization
        </Typography>
      </Stack>
      {!isLoading ? (
        <form>
          <Stack
            spacing={2}
            padding={3}
          >
            <TextField
              name="userName"
              fullWidth
              label="Enter Organization name"
              variant="outlined"
              sx={{
                "& fieldset": {
                  border: "2px solid #231f20",
                  borderRadius: "30px",
                },
                "& .MuiInputLabel-shrink": {
                  color: "#231f20;",
                },
              }}
              onChange={handleFormChange}
              value={orgInfo?.userName}
            />

            {errors.userName && (
              <span className="err_msg">{errors.userName}</span>
            )}

            <TextField
              name="email"
              fullWidth
              label="Enter email address"
              variant="outlined"
              sx={{
                "& fieldset": {
                  border: "2px solid #231f20",
                  borderRadius: "30px",
                },
                "& .MuiInputLabel-shrink": { color: "#231f20;" },
              }}
              onChange={handleFormChange}
              value={orgInfo?.email}
            />
            {errors.email && <span className="err_msg">{errors.email}</span>}

            <LoadScript
              googleMapsApiKey={key}
              libraries={["places"]}
            >
              <StandaloneSearchBox
                onLoad={(ref) => (inputRef.current = ref)}
                onPlacesChanged={handlePlaceChanged}
              >
                <TextField
                  name="location"
                  fullWidth
                  label="Location"
                  variant="outlined"
                  sx={{
                    "& fieldset": {
                      border: "2px solid #231f20",
                      borderRadius: "30px",
                    },
                    "& .MuiInputLabel-shrink": {
                      color: "#231f20;",
                    },
                  }}
                  onChange={handleFormChange}
                  value={orgInfo?.location}
                  placeholder="223 fake street"
                />
              </StandaloneSearchBox>
            </LoadScript>
            {errors?.location && (
              <span className="err_msg">{errors.location}</span>
            )}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
            >
              {/** make own component for this */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="custom-date-input">
                  <DatePicker
                    name="startDate"
                    label={"Start Date"}
                    value={orgInfo?.start_date}
                    onChange={handleStartDate}
                  />
                  {errors?.start_date && (
                    <span className="err_msg">{errors.start_date}</span>
                  )}
                </div>
                <div className="custom-date-input">
                  <DatePicker
                    name="endDate"
                    label={"End Date"}
                    value={orgInfo?.end_date}
                    onChange={handleEndDate}
                  />
                  {errors?.end_date && (
                    <span className="err_msg">{errors.end_date}</span>
                  )}
                </div>
              </LocalizationProvider>
            </Stack>
            <TextField
              name="total_user_allow"
              fullWidth
              label="Enter Total Number Of Employees"
              variant="outlined"
              sx={{
                "& fieldset": {
                  border: "2px solid #231f20",
                  borderRadius: "30px",
                },
                "& .MuiInputLabel-shrink": {
                  color: "#231f20;",
                },
              }}
              value={orgInfo?.total_user_allow}
              onChange={handleFormChange}
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                  maxLength: 10,
                },
              }}
            />

            {errors?.total_user_allow && (
              <span className="err_msg">{errors.total_user_allow}</span>
            )}

            <TextField
              name="price"
              type="number"
              fullWidth
              label="Enter price"
              variant="outlined"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              sx={{
                "& fieldset": {
                  border: "2px solid #231f20",
                  borderRadius: "30px",
                },
                "& .MuiInputLabel-shrink": { color: "#231f20;" },
              }}
              value={orgInfo?.price}
              onChange={handleFormChange}
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                  maxLength: 12,
                },
              }}
            />
            {errors?.price && <span className="err_msg">{errors.price}</span>}

            <Stack
              direction={"row"}
              justifyContent={"space-evenly"}
            >
              <button
                id="save"
                type="button"
                className="yellow-btn"
                style={{
                  backgroundColor: "#f949b2",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmitOrg("save");
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                >
                  <p>Save </p>
                  <SaveIcon sx={{ marginLeft: "10px" }} />
                </Stack>
              </button>
              <button
                type="button"
                className="yellow-btn"
                id="invite"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmitOrg("invite");
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                >
                  <p>Invite </p>
                  <EmailIcon
                    sx={{
                      marginLeft: "10px",
                    }}
                  />
                </Stack>
              </button>
            </Stack>
          </Stack>
          {errors?.page && <span className="err_msg">{errors?.page}</span>}
        </form>
      ) : (
        <div
          className="loader"
          style={{ minHeight: "500px" }}
        >
          {<ReactLoader />}
        </div>
      )}
    </HaptaptModal>
  );
};

export default AddNewOrganizationModal;

// <Modal
//   open={open}
//   aria-labelledby="modal-modal-title"
//   aria-describedby="modal-modal-description"
// >
//   <Box
//     sx={style}
//     className="modal modal-custom"
//   >
//     <div className="modal-dialog modal-dialog-centered modal-dialog-width">
//       <div className="modal-content modal-content-custom">
//         <div
//           onClick={handleClose}
//           className=" position-relative mb-0 text-end pt-3 pe-3"
//           style={{ cursor: "pointer" }}
//         >
//           <img src={IMAGES.CROSS} />
//         </div>
//         <div className="signin-head text-center pb-3">
//           <h1>add new organization</h1>
//         </div>
//         <div className="position-relative px-3">
//           <p>
//             Please enter email address below to send invite link. User can
//             register using the same link to join the platform.
//           </p>
//         </div>
//         <form onSubmit={postAPI}>
//           <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
//             <TextField
//               fullWidth
//               label="Enter Organization name"
//               variant="outlined"
//               sx={{
//                 "& fieldset": {
//                   border: "2px solid #231f20",
//                   borderRadius: "30px",
//                 },
//                 "& .MuiInputLabel-shrink": {
//                   color: "#231f20;",
//                 },
//               }}
//               name="userName"
//               onChange={handleChange}
//               value={fields.userName}
//             />

//             {errors.userName && (
//               <span className="err_msg">{errors.userName}</span>
//             )}
//           </div>
//           <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
//             <TextField
//               fullWidth
//               label="Enter email address"
//               variant="outlined"
//               sx={{
//                 "& fieldset": {
//                   border: "2px solid #231f20",
//                   borderRadius: "30px",
//                 },
//                 "& .MuiInputLabel-shrink": { color: "#231f20;" },
//               }}
//               name="email"
//               onChange={handleChange}
//               value={fields.email}
//             />
//             {errors.email && (
//               <span className="err_msg">{errors.email}</span>
//             )}
//           </div>
//           <div className="mb-5 text-center position-relative submit-head">
//             <button
//               type="submit"
//               className="yellow-btn d-flex align-items-center"
//             >
//               Send Invite{" "}
//               <img
//                 src={IMAGES.ARROW_GREY}
//                 alt=""
//                 className="img-fluid ms-2"
//               />
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   </Box>
// </Modal>;
//  async function postAPI(e) {
//     e.preventDefault();
//     if (handleValidation()) {
//       let data = JSON.stringify({
//         name: fields.userName,
//         email: fields.email,
//       });
//       setLoading(true);
//       let result = await apiHelper.postRequest(
//         "organization/create-organization",
//         data
//       );

//       if (result?.code === CONFIG.statusCode) {
//         setLoading(false);
//         organizationSuccess(result.message);
//         setSearch("");
//         getAPI();
//         handleCloseNewOrgModal();
//         setFields({
//           ...fields,
//           userName: "",
//           email: "",
//         });
//       } else {
//         organizationError(result?.message);
//         setLoading(false);
//       }
//     }
//   }
