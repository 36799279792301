import IMAGES from "../../Middleware/images";

const HaptaptImages = ({
  src,
  alt = "",
  className,
  style,
  height,
  width
}) => {

  return <img style={style} className={className} src={IMAGES[src]} alt={alt} width={width} height={height} />
}

export default HaptaptImages