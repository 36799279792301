import React from "react";
import IMAGES from "../Middleware/images";
import HaptaptModal from "./../Components/ui/HaptaptModal";
import { Box, Stack, Typography, Link } from "@mui/material";
import HaptaptImage from "./../Components/ui/HaptaptImages";
import { IMAGES_INFO } from "./../Middleware/images";
import landscape from "../Assets/Images/landscape.png";

const TutorialModal = ({isOpen, setOpen}) => {
  const handleClose = () => {
    document.body.classList.remove("modal-open");
    setOpen(false);
  };

  const signUpNow = (e) => {
    document.body.classList.remove("modal-open");
    e.preventDefault();
    setOpen(false);
  };
  return (
    <HaptaptModal
      isOpen={isOpen}
      onClose={handleClose}
      boxStyle={{
        width: {
          xs: "350px",
          md: "fit-content",
        },
        padding: 0,
      }}
    >
      <Box
        sx={{
          padding: 2,
          pb: 0,
        }}
      >
        <Stack alignItems={"center"}>

          <HaptaptImage
            src={IMAGES_INFO.HAPTAPT_BLACK}
            width={250}
            alt="haptapt name with a finger pointing to the p"
          />
        </Stack>
        <div className="py-3">
          <p style={{ textAlign: "center" }}>
            haptapt instantly surfaces hidden expertise. Add your relevant
            keywords to each category in just a few steps.
          </p>
        </div>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          background: `url(${landscape})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "20px",
          height: "250px",
          width: "100%",
        }}
      >
        <Stack
          justifyContent={"space-between"}
          sx={{ height: "70%" }}
        >
          <button
            type="button"
            onClick={signUpNow}
            className="yellow-btn d-flex align-items-center"
            style={{ margin: "0 auto" }}
          >
            Sign Up Now{" "}
            <img
              src={IMAGES.ARROW_GREY}
              alt=""
              className="img-fluid ms-2"
            />
          </button>
          <Box>
            <Typography variant="body">Quick onboarding guide</Typography>
            <Link
              tile={
                "https://docs.google.com/presentation/d/1LvqqCT-fyFlxdC1-8tB_k-T3g7E5A9ekbK5YYoq3FtM/edit?slide=id.g342b1853c0d_2_83#slide=id.g342b1853c0d_2_83"
              }
              target="_blank"
              href="https://docs.google.com/presentation/d/1LvqqCT-fyFlxdC1-8tB_k-T3g7E5A9ekbK5YYoq3FtM/edit?slide=id.g342b1853c0d_2_83#slide=id.g342b1853c0d_2_83"
              sx={{
                textDecoration: "none",
              }}
            >
              <button
                className="yellow-btn d-flex align-items-center"
                style={{
                  fontFamily: "inherit",
                  margin: "0 auto",

                  width: "fit-content",
                  fontWeight: "700",
                  backgroundColor: "#f949b2",
                }}
              >
                Click Here
              </button>
            </Link>
          </Box>
        </Stack>
        {/* <HaptaptB */}
      </Box>
    </HaptaptModal>
  );
};

export default TutorialModal;
