import React, { useEffect, useState } from "react";
import IMAGES from "../../Middleware/images";
import { Helmet } from "react-helmet";
import titles from "../../Helper/title";
import apiHelper from "../../Helper/api-helper";
import CONFIG from "../../Helper/config";
import ReactLoader from "../../ControlComponents/react-loader";
import "../../Assets/Styles/organization-member.css";
import { useMemo } from "react";
import Pagination from "../../Components/pagination";

let PageSize = 10;
let PageSize1 = 10;
const Billing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [billingList, setBillingList] = useState();
  const [isLoading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pdfLink, setPdfLink] = useState('');
  const [filterBillingList, setFilterBillingList] = useState([]);
  const [search, setSearch] = useState("");

  const handleRowClick = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  async function getAPI() {
    setLoading(true);
    let result = await apiHelper.getRequest("auth/billing-list");
    if (result?.code === CONFIG.statusCode) {
      setBillingList(result?.body);
      setLoading(false);
    } else {
    setLoading(false)
    }
  }

  async function getInvoiceAPI(invoiceId) {
    setLoading(true);
    let result = await apiHelper.getRequest(`auth/invoice-download?invoice_id=${invoiceId}`);
    if (result?.code === CONFIG.statusCode) {
      setPdfLink(result?.body?.pdf);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (pdfLink) {
      const link = document.createElement('a');
      link.href = pdfLink;
      link.target = '_blank';
      link.download = 'document.pdf';
      link.click();
    }
  }, [pdfLink]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return billingList?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, billingList]);

  billingList?.sort((a, b) => a?.user_tans_data?.name?.localeCompare(b?.user_tans_data?.name))

  const currentTableData1 = useMemo(() => {
    const firstPageIndex = (currentPage1 - 1) * PageSize1;
    const lastPageIndex = firstPageIndex + PageSize1;
    return filterBillingList?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage1, filterBillingList]);

  useEffect(() => {
    getAPI();
  }, []);

  const handleSearch = (e) => {
    let text = e.target.value;
    setSearch(text);
    const filteredProducts = billingList?.filter((product) =>
      product?.user_tans_data?.name?.toLowerCase().includes(search.toLowerCase())
    );
    setFilterBillingList(filteredProducts);
  };
  const handleClear = () => {
    setSearch("");
  };

  return (
    <>
      <Helmet>
        <title>{titles.billing}</title>
      </Helmet>
      <div className="loader">{isLoading && <ReactLoader />}</div>
      <section className="pt-5">
        <div className="container">
          <div className="col-12">
            <div className="row align-items-center justify-content-center">
              <div className="row align-items-center">
                <div className="row align-items-center pb-4">
                  <div className="col-12 col-sm-5">
                    <div className="list-head">
                      <h2>Billing</h2>
                    </div>
                  </div>
                  <div className="col-12 col-sm-7">
                    <div className="category-head d-block d-sm-flex justify-content-start justify-content-sm-end align-items-center gap-2 gap-xl-5">
                      <div className="search-category position-relative mb-2 mb-sm-0">
                        <input
                          type="serach"
                          placeholder="Search organization..."
                          value={search}
                          onChange={(e) => handleSearch(e)}
                        />
                        <img
                          src={IMAGES.SEARCH_ICON}
                          className="search-icon"
                          alt=""
                        />

                        {search !== "" && <img src={IMAGES.CROSS_1} className="searchCross" style={{ cursor: "pointer" }} onClick={handleClear} />}

                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {
                    search.length <= 0 ?
                      <>


                        <div className="table-responsive organization-table member-table ">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div class="form-check">

                                  </div>
                                </th>
                                <th scope="col">Organization Name</th>
                                <th scope="col">Date of Payment</th>
                                <th scope="col">Subscription</th>
                                <th scope="col">Status</th>
                                <th scope="col">Invoice</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                currentTableData?.map((org) => {
                                  return (
                                    <tr className={selectedRows.includes(org.id) ? 'active' : ''}
                                    >
                                      <td>
                                        <div class="form-check">
                                          <input
                                            className="form-check-input table-check"
                                            type="checkbox"
                                            value=""
                                            id={`checkbox-${org.id}`}
                                            checked={selectedRows.includes(org.id)}
                                            onChange={() => handleRowClick(org.id)}
                                          />
                                        </div>
                                      </td>
                                      <td>{org?.user_tans_data?.name}</td>
                                      <td>{org?.start_date}</td>
                                      <td> {(org.plan_data?.name !== "enterprise custom pricing" ? org?.plan_data?.name : "enterprise")}</td>
                                      <td>
                                        <div className="table-content">
                                          <p className="table-content-item item-paid">
                                            Paid
                                          </p>
                                        </div>
                                      </td>
                                      <td style={{ cursor: "pointer" }} onClick={() => getInvoiceAPI(org.invoice_id)}>
                                        <img src={IMAGES.PDF_ICON} alt="" />
                                        {org?.start_date}
                                      </td>
                                    </tr>
                                  );
                                })}

                            </tbody>
                          </table>
                        </div>
                        <div className="row align-items-center mt-2">
                          <div className="col-6 mt-2">
                            <div className="pagination-left">
                              <nav aria-label="...">
                                <ul className="custom-pagination">
                                  <li className="page-item active" aria-current="page">
                                    <span className="page-link  cus-page-item">Page</span>
                                  </li>
                                  <li className="page-item cus-page-item">
                                    <a className="page-link">{currentPage}</a>
                                  </li>
                                  <li className="page-item cus-page-item">
                                    <a className="page-link">of</a>
                                  </li>
                                  <li className="page-item cus-page-item">
                                    <a className="page-link">{Math.ceil(billingList?.length / PageSize)}</a>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                          <div className="col-6 mt-2">
                            <div className="pagination-right">
                              <nav aria-label="Page navigation example">

                                {billingList?.length > PageSize &&
                                  <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={billingList?.length}
                                    pageSize={PageSize}
                                    onPageChange={(page) => setCurrentPage(page)}
                                  />}
                              </nav>
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <>  {currentTableData1.length <= 0 ? (
                        <p className="notOrganization">No organization found</p>
                      ) :
                        <div className="table-responsive organization-table member-table">

                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="form-check">

                                  </div>
                                </th>
                                <th scope="col">Organization Name</th>
                                <th scope="col">Date of Payment</th>
                                <th scope="col">Subscription</th>
                                <th scope="col">Status</th>
                                <th scope="col">Invoice</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentTableData1?.map((org) => (
                                <tr className={selectedRows.includes(org.id) ? 'active' : ''} key={org.id}>
                                  <td scope="col">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input table-check"
                                        type="checkbox"
                                        value=""
                                        id={`checkbox-${org.id}`}
                                        checked={selectedRows.includes(org.id)}
                                        onChange={() => handleRowClick(org.id)}
                                      />
                                    </div>
                                  </td>
                                  <td>{org?.user_tans_data?.name}</td>
                                  <td>{org?.start_date}</td>
                                  <td>{org?.plan_data?.name !== "enterprise custom pricing" ? org?.plan_data?.name : "enterprise"}</td>
                                  <td>
                                    <div className="table-content">
                                      <p className="table-content-item item-paid">Paid</p>
                                    </div>
                                  </td>
                                  <td style={{ cursor: "pointer" }} onClick={() => getInvoiceAPI(org.invoice_id)}>
                                    <img src={IMAGES.PDF_ICON} alt="" />
                                    #{org?.start_date}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>
                      }
                        {
                          currentTableData1.length > 0 &&
                          (
                            <div className="row align-items-center mt-2">
                              <div className="col-6 mt-2">
                                <div className="pagination-left">
                                  <nav aria-label="...">
                                    <ul className="custom-pagination">
                                      <li className="page-item active" aria-current="page">
                                        <span className="page-link  cus-page-item">Page</span>
                                      </li>
                                      <li className="page-item cus-page-item">
                                        <a className="page-link">{currentPage}</a>
                                      </li>
                                      <li className="page-item cus-page-item">
                                        <a className="page-link">of</a>
                                      </li>
                                      <li className="page-item cus-page-item">
                                        <a className="page-link">{Math.ceil(filterBillingList?.length / PageSize1)}</a>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                              <div className="col-6 mt-2">
                                <div className="pagination-right">
                                  <nav aria-label="Page navigation example">

                                    {filterBillingList?.length > PageSize1 &&
                                      <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage1}
                                        totalCount={filterBillingList?.length}
                                        pageSize={PageSize1}
                                        onPageChange={(page) => setCurrentPage1(page)}
                                      />}
                                  </nav>
                                </div>
                              </div>
                            </div>
                          )
                        }

                      </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Billing;
