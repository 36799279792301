import React, { useState, useEffect, useRef } from 'react'
import "../../Assets/Styles/search-billing.css"
import IMAGES from '../../Middleware/images'
import ReactLoader from '../../ControlComponents/react-loader';
import OrganizationProfile from "../Admin/profile-organization";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import titles from '../../Helper/title';
import ERR_MESSAGE from '../../Helper/error-helper';
import apiHelper from '../../Helper/api-helper';
import CONFIG from "../../Helper/config"
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ROUTES from '../../Middleware/routes';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useLocation } from 'react-router-dom';
import { Autocomplete } from '@mui/material';
const libraries = ['places'];


function Setting(props) {

    const [isEnable, setIsenable] = useState(false);

    const [tapModalOpen, setTapModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [eye_icon1, setEye_icon1] = useState(IMAGES.EYE_HIDE);
    const [type1, setType1] = useState("password");
    const [msg1, setMsg1] = useState("reveal password")
    const [eye_icon2, setEye_icon2] = useState(IMAGES.EYE_HIDE);
    const [type2, setType2] = useState("password");
    const [msg2, setMsg2] = useState("reveal password")
    const [eye_icon3, setEye_icon3] = useState(IMAGES.EYE_HIDE);
    const [type3, setType3] = useState("password");
    const [msg3, setMsg3] = useState("reveal password")
    const [fields1, setFields1] = useState({
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
        email: "",
        name: "",
        place: "",
        roleTitle: "",
        category: "",
        subCat: "",
        subCatArr: [],
        longitude: "",
        latitude: "",
    })

    const inputRef = useRef(null);
    let key = "AIzaSyDFkw6-U8mAQEGTOC77ZYrIojD4isGoNgg";

    const handlePlaceChanged = () => {
        setErrors("")
        const [place] = inputRef?.current?.getPlaces();

        if (place) {
            setFields1({
                ...fields1,
                place: place.formatted_address,
                latitude: `${place.geometry.location.lat()}`,
                longitude: `${place.geometry.location.lng()}`,
            });

        }
    };
    const [categoryList, setCategoryList] = useState([]);

    const [editId, setEditId] = useState([]);
    const [superAdminDetail, setSuperAdminDetail] = useState("");
    const [pkg, setPkg] = useState(null);
    const navigate = useNavigate()


    const handleOpen = () => {
        document.body.classList.add("modal-open");
        setFields1({
            ...fields1,
            latitude: superAdminDetail.latitude,
            longitude: superAdminDetail.longitude,
            name: superAdminDetail.name,
            place: superAdminDetail?.location,
            email: superAdminDetail.email,
            roleTitle: superAdminDetail.designation,
            member_tap_limit:superAdminDetail?.tap_count?.toString()
        })
        setOpen(true);
    }

    const handleClose = () => {
        document.body.classList.remove("modal-open");
        setErrors("")
        setOpen(false);
    }

    const handleEditOpen = (id, catName, subcatdata) => {
        document.body.classList.add("modal-open");
        setEditId(id);
        setEditOpen(true);
        setFields1({
            ...fields1,
            category: catName,
            subCat: "",
            subCatArr: subcatdata.map((item) => item.name)
        })
    }

    function getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', { month: 'long' });
    }

    const handleEditClose = () => {
        document.body.classList.remove("modal-open");
        setFields1(
            {
                ...fields1,
                category: "",
                subCat: "",
                subCatArr: []
            }
        )
        setEditOpen(false);
    }

    const handleChangePasswordOpen = () => {
        document.body.classList.add("modal-open");
        setOpenChangePassword(true);
    }

    const handleChangePasswordClose = () => {
        document.body.classList.remove("modal-open");
        setFields1(
            {
                ...fields1,
                oldPassword: "",
                newPassword1: "",
                newPassword2: "",
            }
        )
        setErrors("");
        setOpenChangePassword(false)
    };

    const [errors, setErrors] = useState("");
    const [fields, setFields] = useState({
        email: "",
    });

    const showOldPassword = () => {
        if (type3 === "password") {
            setType3("text");
            setEye_icon3(IMAGES.EYE_SHOW);
            setMsg3("hide password")
        }
        else {
            setType3("password");
            setEye_icon3(IMAGES.EYE_HIDE);
        }
    }
    const showPassword = () => {
        if (type1 === "password") {
            setType1("text");
            setEye_icon1(IMAGES.EYE_SHOW);
            setMsg1("hide password")
        }
        else {
            setType1("password");
            setEye_icon1(IMAGES.EYE_HIDE);
        }
    }
    const showConfirmPassword = () => {
        if (type2 === "password") {
            setType2("text");
            setEye_icon2(IMAGES.EYE_SHOW);
            setMsg2("hide password")
        }
        else {
            setType2("password");
            setEye_icon2(IMAGES.EYE_HIDE);
        }
    }


    const handleChange = (e) => {
        setErrors("");

        const name = e.target.name;
        const value = e.target.value;

        if (name === "place") {
            setFields1({
                ...fields1,
                [name]: value,
                latitude: "",
                longitude: ""
            });
        }
        else {
            setFields1({
                ...fields1,
                [name]: value,
            });
        }

    };

    const handleChange2 = (e) => {
        setErrors("");
        const name = e.target.name;
        const value = e.target.value;
        setFields1({
            ...fields1,
            [name]: value,
        });
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let passwordReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
        const { oldPassword, newPassword1, newPassword2 } = fields1;

        if (oldPassword.trim() == "") {
            formIsValid = false
            errors["oldPassword"] = ERR_MESSAGE.OLD_PASSWORD_EMPTY
        } else if (passwordReg.test(oldPassword) === false) {
            formIsValid = false
            errors["oldPassword"] = ERR_MESSAGE.INVALID_PASSWORD
        }
        if (newPassword1.trim() == "") {
            formIsValid = false
            errors["newPassword1"] = ERR_MESSAGE.NEW_PASSWORD_EMPTY
        } else if (passwordReg.test(newPassword1) === false) {
            formIsValid = false
            errors["newPassword1"] = ERR_MESSAGE.INVALID_PASSWORD
        }
        if (newPassword2.trim() == "") {
            formIsValid = false
            errors["newPassword2"] = ERR_MESSAGE.CONFIRM_PASSWORD_EMPTY
        }
        else if (newPassword2.trim() !== newPassword1.trim()) {
            formIsValid = false
            errors["newPassword2"] = ERR_MESSAGE.NOTMATCHED
        }
        setErrors(errors);
        return formIsValid;
    };

    const handleValidation2 = () => {
        let errors = {};
        let formIsValid = true;

        const { category, subCatArr } = fields1;
        if (!category || category.trim().length === 0) {
            formIsValid = false;
            errors["category"] = ERR_MESSAGE.CATEGORY_EMPTY
        }
        else if (!subCatArr || subCatArr.length === 0) {
            formIsValid = false;
            errors["subcatArr"] = ERR_MESSAGE.OPTIONS_EMPTY
        }
        setErrors(errors);
        return formIsValid;
    };

    const handleValidationProfile = () => {
        let errors = {};
        let formIsValid = true;
        const emojiSymbolRegex = /[^\p{L}\s]/u;

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        const { email, name, place, longitude, latitude, roleTitle } = fields1;
        const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
        if (!name || name.trim().length === 0) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.EMPTY_NAME
        } else if (name.length < 3) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_NAME_MIN
        }
        else if (name.length > 30) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_NAME_MAX
        }
        else if (emojiRegex.test(name)) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_NAME;
        }
        if (!email || email.trim().length === 0) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_EMPTY
        }
        else if (reg.test(email.toLowerCase()) === false) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID
        }
        if (!place || place.trim().length === 0) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.LOCATION_EMPTY
        }
        else if (!longitude || longitude.trim().length === 0 || !latitude || latitude.trim().length === 0) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.INVALID_LOCATION;
        }
        else if (emojiRegex.test(place)) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.EMOJI;
        }
        if (!roleTitle || roleTitle.trim().length === 0) {
            formIsValid = false;
            errors["roleTitle"] = ERR_MESSAGE.ROLE_EMPTY
        }
        setErrors(errors);
        return formIsValid;
    };
    const success = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }
    const error = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }
    const handleTapModalOpen = () => {
        document.body.classList.add("modal-open");
        setFields1({
            ...fields1,
            tapLimit: superAdminDetail.tap_count
        })
        setTapModalOpen(true);
    }
    const handleTapModalClose = () => {
        document.body.classList.remove("modal-open");
        setTapModalOpen(false);
    }

    async function postAPI(e) {
        e.preventDefault();
        if (handleValidation()) {
            let data = JSON.stringify({
                "oldPassword": fields1.oldPassword,
                "newPassword": fields1.newPassword2,
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/change-passsword", data)
            if (result?.code === CONFIG.statusCode) {
                setLoading(false);
                success(result.message)
                handleChangePasswordClose()
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }
    const handleValidationTapLimit = () => {
        let errors = {};
        let formIsValid = true;

        const { tapLimit } = fields1;
        if (!tapLimit || tapLimit.length === 0) {
            formIsValid = false;
            errors["tapLimit"] = ERR_MESSAGE.TAP_EMPTY
        }
        else if (parseInt(tapLimit) < 1) {
            formIsValid = false;
            errors["tapLimit"] = ERR_MESSAGE.TAP_INVALID
        }
        setErrors(errors);
        return formIsValid;
    }

    async function postAPISetTapLimit(e) {
        e.preventDefault();
        if (handleValidationTapLimit()) {
            let data = JSON.stringify({
                "id": localStorage.getItem("id"),
                "total_tap": parseInt(fields1.tapLimit)

            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/set-your-organization", data)
            if (result?.code === CONFIG.statusCode) {
                setLoading(false);
                success(result.message)
                handleTapModalClose()
                getMyDetailAPI();
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }



    async function postAPIEditProfile(e) {
        e.preventDefault();
        if (handleValidationProfile()) {
            let data = JSON.stringify({
                "name": fields1.name,
                "id": localStorage.getItem("id"),
                "designation": fields1.roleTitle,
                "location": fields1.place,
                "latitude": fields1.latitude,
                "longitude": fields1.longitude,
                "member_tap_limit": fields1?.member_tap_limit

            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/set-your-organization", data)
            if (result?.code === CONFIG.statusCode) {
                setLoading(false);
                success(result.message)
                handleClose();
                getMyDetailAPI();
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }

    async function getMyDetailAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("auth/get-organization-details")
        if (result?.code === CONFIG.statusCode) {
            console.log("getMyDetailAPI", result.body)
            setSuperAdminDetail(result.body)
            setLoading(false)
            setIsenable(result?.body?.tap_data == 1 ? true : false)
        }
        else {
            setLoading(false)
        }
    }

    async function getAPIPackageList() {
        setLoading(true);
        let result = await apiHelper.getRequest(`auth/package-list`);
        if (result?.code === CONFIG.statusCode) {
            let pkgArr = result?.body;
            for (let i = 0; i < pkgArr.length; i++) {
                if (pkgArr[i].is_package === 1) {
                    setPkg(pkgArr[i]);
                    break;
                }
            }

            setLoading(false);
        } else {
            setLoading(false);
        }
    }
    async function getAPICategoryList() {
        setLoading(true)
        let result = await apiHelper.getRequest("auth/admin-category-list?id=" + localStorage.getItem("id")+"&sort=alpha")
        if (result?.code === CONFIG.statusCode) {

            setCategoryList(result.body)

            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }

    async function updateEmployeStatus(e) {
        e.preventDefault();

        setIsenable(!isEnable)
        let data = JSON.stringify({
            "id": localStorage.getItem("id"),
            "tap_data": !isEnable

        });
        setLoading(true);
        let result = await apiHelper.postRequest("auth/set-your-organization", data)
        if (result?.code === CONFIG.statusCode) {
            setLoading(false);
            success(result.message)
            handleTapModalClose()
            getMyDetailAPI();
        }
        else {
            error(result.message)
            setLoading(false)
        }
    }

    useEffect(() => {
        getRoleAPI();
        getMyDetailAPI();
        getAPIPackageList();
        getAPICategoryList();
    }, [])

    const [capture, setCapture] = useState(true);
    const [openDropdown, setOpenDropdown] = useState(false)

    const [roleList, setRoleList] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState(roleList);


    const closeDropdown = () => {
        setOpenDropdown(false);
    };

    const handleInputChange = (event, newValue) => {
        setErrors("");
        const filteredRoles = roleList?.filter(role => role.toLowerCase().includes(newValue.toLowerCase()));
        setFilteredOptions(filteredRoles);
        setFields1(prevFields => ({
            ...prevFields,
            roleTitle: newValue
        }));
    };

    const handleSelectionChange = (event, newValue) => {

        setErrors("")
        if (event.key === 'Enter' || event.code === 'NumpadEnter') {
            setOpenDropdown(false);
        }
        setFields1(prevFields => ({
            ...prevFields,
            roleTitle: newValue,
        }));
        setOpenDropdown(false);
    };

    const handleDropdownChange2 = (e) => {
        setErrors("");
        const name = e.target.name;
        const value = e.target.value;
        setFields1(prevFields => ({
            ...prevFields,
            [name]: value
        }));
        if (e.target.value === "" || roleList.includes(value)) {
            setOpenDropdown(false)
        } else {
            setOpenDropdown(true);
        }

    };

    async function getRoleAPI() {
        setLoading(true);
        let result = await apiHelper.getRequest(`organization/role-list`);
        if (result?.code === CONFIG.statusCode) {
            setRoleList(result?.body);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>{titles.profileSetting}</title>
            </Helmet>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <div className="col-12 col-md-6 col-xl-4 pe-3 pe-sm-0">
                <div className="card-box-main mb-4 mb-xl-0">
                    <div className="card-box-img d-flex justify-content-between ">
                        <h3 className="mb-3">your organization</h3>
                        <img style={{ cursor: "pointer" }} src={IMAGES.EDIT_ICON} alt="edit-icon" className="img-fluid" onClick={handleOpen} />
                    </div>
                    <h4 className='mb-4'>{superAdminDetail?.name?.replace(/-/g, ' ')}</h4>
                    <div className="card-box-component mb-4 d-flex justify-content-between">
                        <div className=''><h5 className='no-rap'>Headquarters</h5></div>
                        <div className='w-100' style={{ borderBottom: 'dotted 2px #898787', height: "13px", margin: "5px" }}></div>
                        <div className='w-100'><h5>{superAdminDetail.location}</h5></div>
                    </div>
                    <div className="card-box-component mb-4 d-flex justify-content-between">
                        <div className=''><h5 className='no-rap'>Total Members</h5></div>
                        <div className='w-100' style={{ borderBottom: 'dotted 2px #898787', height: "13px", margin: "5px" }}></div>
                        <div className='w-100'><h5 >{superAdminDetail.total_member} </h5></div>
                    </div>
                    <div className="card-box-component  d-flex justify-content-between" style={{
                        marginBottom: "2px !important",
                    }}>
                        <div className=''><h5 className='no-rap'>Member Tap Limit</h5></div>
                        <div className='w-100' style={{ borderBottom: 'dotted 2px #898787', height: "13px", margin: "5px" }}></div>
                        <div className='w-100'><h5>{ superAdminDetail?.tap_count} </h5></div>
                    </div>
                    <div className="card-box-component ">
                        <p>This is the number of taps your team<br />members can be tapped each month.</p>
                    </div>
                    <div className="mx-auto text-start" style={{ cursor: "pointer" }}>
                        <div onClick={handleOpen} className="company-info-btns position-relative mt-3">
                            <button type="button" className="yellow-btn d-flex align-items-center">Edit Organization <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-12 col-md-6 col-xl-8 pe-3 pe-sm-0">
                <div className='row' >
                    <div className="col-12 col-md-6  pe-3 pe-sm-0">
                        <div className="card-box-main mb-4 mb-xl-0">
                            <div className="card-box-img d-flex justify-content-between ">
                                <h3 className="mb-3">your custom categories</h3>
                            </div>
                            <div className="card-box-sub-head d-flex justify-content-between align-items-start">
                                <div className="card-category-exp-filters">
                                    {categoryList.map((item) => {
                                        return (
                                            <p className="card-category-filter-chips">{item.name}<img src={IMAGES.CROSS} className="card-chip-img" alt="img-fluid" /></p>
                                        );
                                    })}

                                </div>
                            </div>
                            <div className="card-box-btn">
                                <div className="text-start position-relative card-submit-head d-flex align-items-center gap-2 gap-lg-4 justify-content-start mt-4 mb-4">
                                    <button type="button" onClick={() => { navigate(ROUTES.ORGANIZATION_CATEGORY) }} className="yellow-btn position-relative d-flex align-items-center">EDIT CATEGORIES <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2 " /></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 pe-3 pe-sm-0">
                        <div className="card-box-main mb-4 mb-xl-0">
                            <div className="card-box-img d-flex justify-content-between ">
                                <h3 className="mb-3">your subscription</h3>
                            </div>
                            <h4 className='mb-4'>Enterprise Custom Pricing</h4>
                            <div className="card-box-component mb-4 d-flex justify-content-between">
                                <div className=''><h5 className='no-rap' style={{fontSize:"small"}}>SUBSCRIPTION PERIOD: {superAdminDetail?.start_date?.slice(0, 10)?.replace("-","/")?.replace("-","/")} - {superAdminDetail?.end_date?.slice(0, 10)?.replace("-","/")?.replace("-","/")}</h5></div>
                            </div>
                            <div className="card-box-btn mb-4">
                                <div className="text-start position-relative card-submit-head d-flex align-items-center gap-2 gap-lg-4 justify-content-start mt-4 mb-3">
                                    <button type="submit" onClick={() => { window.location.href="mailto:support@haptapt.com" }} className="yellow-btn position-relative d-flex align-items-center">Change Plan <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2 " /></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='row mt-3'>
                    <div className="col-12 col-md-6  pe-3 pe-sm-0">
                        <div className="card-box-main mb-4 mb-xl-0">
                            <div className="card-box-img d-flex justify-content-between ">
                                <h3 className="mb-3">your organization admins</h3>
                            </div>
                            {superAdminDetail?.membersList?.map((item, index) => {
                                 return (<div className="card-box-component mb-4 d-flex justify-content-between">
                                    <div className=''><h5 className='no-rap'>{item.name} </h5></div>
                                    <div className='w-100' style={{ borderBottom: 'dotted 2px #898787', height: "13px", margin: "5px" }}></div>
                                    <div className='w-100'><h5 className='no-rap'>{item.designation}</h5></div>
                                </div>)
                            })}

                            {/* <div className="card-box-component mb-4 d-flex justify-content-between">
                                <div className=''><h5 className='no-rap'>Devon Lane </h5></div>
                                <div className='w-100' style={{ borderBottom: 'dotted 2px #898787', height: "13px", margin: "5px" }}></div>
                                <div className='w-100'><h5 >Head of HR</h5></div>
                            </div>
                            <div className="card-box-component mb-4 d-flex justify-content-between">
                                <div className=''><h5 className='no-rap'>Devon Lane </h5></div>
                                <div className='w-100' style={{ borderBottom: 'dotted 2px #898787', height: "13px", margin: "5px" }}></div>
                                <div className='w-100'><h5 >Head of People & Culture</h5></div>
                            </div>
                            <div className="card-box-component mb-4 d-flex justify-content-between">
                                <div className=''><h5 className='no-rap'>Devon Lane </h5></div>
                                <div className='w-100' style={{ borderBottom: 'dotted 2px #898787', height: "13px", margin: "5px" }}></div>
                                <div className='w-100'><h5 >CEO</h5></div>
                            </div> */}
                        </div>

                    </div>

                    <div className="col-12 col-md-6 pe-3 pe-sm-0">
                        <div className="card-box-main mb-4 mb-xl-0" style={{ paddingBottom: 0 }}>
                            <div className="card-box-img d-flex justify-content-between ">
                                <h3 className="mb-3">need support?</h3>
                            </div>
                            <h6 className='mb-4'>Reach out to a <a href='mailto:support@haptapt.com'> support@haptapt.com</a></h6>
                            <div className="card-box-component mb-4 d-flex justify-content-between" style={{ position: "relative", left: "-24px", borderRadius: "10px", marginTop: "44px" }}>
                                <img src={IMAGES.PROFILE_SUPPORT} alt="Two animals high fiving" class="support-image" width={250} />

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box  className="modal modal-custom">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                        <div className="modal-content modal-content-custom">
                            <div onClick={handleClose} className='position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                                <img src={IMAGES.CROSS} />
                            </div>
                            <div className="signin-head text-center pb-3">
                                <h1 >edit organization</h1>
                            </div>
                            <form onSubmit={postAPIEditProfile}>
                                <div className="editbottom position-relative mx-4 mx-sm-5">
                                    <label className="form-label email-label mb-0 email-input">Your Name</label>
                                    <input type="text" name="name" onChange={handleChange} error={errors.name}
                                        value={fields1.name?.replace(/-/g, ' ')} className="form-control comm-radius comm-input" placeholder="Enter Your Name" />
                                    <span className="err_msg">{errors.name}</span>
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5">
                                    <label className="form-label email-label mb-0 email-input">Email address</label>
                                    <input type="text" name="email" onChange={handleChange} error={errors.email}
                                        value={fields1.email} className="form-control comm-radius comm-input" disabled />
                                    <span className="err_msg">{errors.email}</span>
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5">
                                    <LoadScript
                                        googleMapsApiKey={key}
                                        libraries={libraries}
                                    >

                                        <StandaloneSearchBox
                                            ref={inputRef}
                                            onLoad={(ref) => (inputRef.current = ref)}
                                            onPlacesChanged={handlePlaceChanged}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Location"
                                                variant="outlined"
                                                sx={{
                                                    "& fieldset": {
                                                        border: "2px solid #231f20",
                                                        borderRadius: "30px",
                                                    },
                                                    "& .MuiInputLabel-shrink": {
                                                        color: "#231f20;",
                                                    },
                                                }}
                                                name="place"
                                                onChange={handleChange}
                                                value={fields1.place}
                                                placeholder="Enter location"
                                            />
                                        </StandaloneSearchBox>
                                    </LoadScript>
                                    {errors?.place && <span className="err_msg">{errors.place}</span>}
                                </div>
                                <div className="editbottom mb-5 my-4 text-end role-custome mx-5">
                                    <Autocomplete
                                        fullWidth
                                        options={filteredOptions}
                                        onBlur={closeDropdown}
                                        getOptionLabel={(option) => option}
                                        value={fields1?.roleTitle}
                                        onInputChange={handleInputChange}
                                        onChange={handleSelectionChange}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" || e.code === "NumpadEnter") {
                                                setOpenDropdown(false);
                                            }
                                        }}
                                        open={openDropdown}
                                        disableClearable
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                onChange={(e) => handleDropdownChange2(e)}
                                                label="Role/Title"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment></React.Fragment>
                                                    ),
                                                }}
                                                onFocusCapture={() => {
                                                    setOpenDropdown(false);
                                                }}
                                                sx={{
                                                    "& fieldset": {
                                                        border: "2px solid #231f20",
                                                        borderRadius: "30px",
                                                    },
                                                    "& .MuiInputLabel-shrink": {
                                                        color: "#231f20;",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                    {/* <input type="text" name="roleTitle" onChange={handleChange} error={errors.roleTitle}
                                        value={fields1.roleTitle} className="form-control comm-radius comm-input" placeholder="Enter Role/Title" /> */}
                                    <span className="err_msg">{errors.roleTitle}</span>
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5">
                                    <label className="form-label email-label mb-0 email-input">Member Tap Limit</label>
                                    <input type="number" name="member_tap_limit" onChange={handleChange} error={errors.member_tap_limit}
                                        value={fields1.member_tap_limit?.replace(/-/g, ' ')} className="form-control comm-radius comm-input" placeholder="Member Tap Limit" />
                                    <span className="err_msg">{errors.member_tap_limit}</span>
                                </div>
                                <div className="editbottom text-center position-relative submit-head">
                                    <button type="submit" className="yellow-btn align-items-center">Save <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={tapModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box  className="modal modal-custom">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                        <div className="modal-content modal-content-custom">
                            <div onClick={handleTapModalClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                                <img src={IMAGES.CROSS} />
                            </div>
                            <div className="signin-head text-center pb-3">
                                <h1 >edit tap limit</h1>
                            </div>
                            <form onSubmit={postAPISetTapLimit}>
                                <div className="mb-5 position-relative mx-5">
                                    <TextField type="number" fullWidth label="Tap limit" variant="outlined" placeholder='Enter tap limit'
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="tapLimit" onChange={handleChange} value={fields1.tapLimit} />
                                    {errors.tapLimit && <span className="err_msg">{errors.tapLimit}</span>}
                                </div>
                                <div className="mb-5 text-center position-relative submit-head">
                                    <button type="submit" className="yellow-btn d-flex align-items-center">Save  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openChangePassword}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box  className="modal modal-custom">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                        <div className="modal-content modal-content-custom">
                            <div onClick={handleChangePasswordClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                                <img src={IMAGES.CROSS} />
                            </div>
                            <div className="signin-head text-center pb-3">
                                <h1>change password</h1>
                            </div>
                            <form onSubmit={postAPI}>
                                <div className="editbottom position-relative mx-4 mx-sm-5 forgot-input">
                                    <TextField type={type3} fullWidth label="Old password" variant="outlined"
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="oldPassword" onChange={handleChange2} value={fields.oldPassword} />

                                    <img src={eye_icon3} alt="" onClick={showOldPassword} className="hiden-eye" title={msg3} />
                                    {errors.oldPassword && <span className="err_msg">{errors.oldPassword}</span>}
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5 forgot-input">
                                    <TextField type={type1} fullWidth label="new password" variant="outlined"
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="newPassword1" onChange={handleChange2} value={fields.newPassword1} />

                                    <img src={eye_icon1} alt="" onClick={showPassword} className="hiden-eye" title={msg1} />
                                    {errors.newPassword1 && <span className="err_msg">{errors.newPassword1}</span>}
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5 forgot-input">
                                    <TextField type={type2} fullWidth label="new password" variant="outlined"
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="newPassword2" onChange={handleChange2} value={fields.newPassword2} />

                                    <img src={eye_icon2} alt="" onClick={showConfirmPassword} className="hiden-eye" title={msg2} />
                                    {errors.newPassword2 && <span className="err_msg">{errors.newPassword2}</span>}
                                </div>
                                <div className="editbottom text-center position-relative submit-head">
                                    <button type="submit" className="yellow-btn align-items-center">Save <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>

                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
            <ToastContainer rtl />
        </>
    )
}


function OrganizationSetting() {
    const location = useLocation();
    const { profile } = location.state || {};

    const [tab, setTab] = useState(0);
    const [isActive, setIsActive] = useState(profile);
    const [isLoading, setLoading] = useState(false);


    useEffect(()=>{
        setIsActive(profile);
    },[location])

    return (
        <>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <section className="serach-result-section">
                <div className="container" style={{ paddingTop: "15px"}}>
                    <div className="row">
                        {
                            isActive ? <OrganizationProfile /> : <Setting setIsActive={setIsActive} />
                        }

                    </div>

                </div>
            </section>

        </>
    )
}

export default OrganizationSetting