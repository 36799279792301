import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import IMAGES from "../../../Middleware/images";
const style = {
};
export const InviteSent = (props) => {

    return (<>
        <Modal
            open={props.invteModel}

            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="modal modal-custom">
                <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                    <div className="modal-content modal-content-custom">
                        <div onClick={() => props.setInviteModel(false)} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                            <img src={IMAGES.CROSS} />
                        </div>
                        <div className="signin-head text-center pb-3">
                            <h1>Invite Sent</h1>
                        </div>

                        <div className="position-relative px-4" style={{textAlign: "center"}}>
                            <p>
                                An invitation has been sent to this member’s email address.
                            </p>
                            <p>
                                Now they can join and add/edit their profile.
                            </p>

                        </div>

                        <div className="m-5 text-center position-relative submit-head" onClick={() => { props.setInviteModel(false); props.getAPI() }}>
                            <button type="submit" className="yellow-btn d-flex align-items-center">All Done! <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                        </div>

                    </div>
                </div>
            </Box>
        </Modal>
    </>)
}