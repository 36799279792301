import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IMAGES from "../../Middleware/images";
import ClearIcon from "@mui/icons-material/Clear";
import "../../Assets/Styles/profile.css";
import ERR_MESSAGE from "../../Helper/error-helper";
import apiHelper from "../../Helper/api-helper";
import ReactLoader from "../../ControlComponents/react-loader";
import CONFIG from "../../Helper/config";
import ROUTES from "../../Middleware/routes";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Typography, Tooltip, Stack, Link } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import titles from "../../Helper/title";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { InviteSent } from "./component/invite-sent";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
const libraries = ["places"];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EmployeeProfile = () => {
  const [invteModel, setInviteModel] = useState(false);

  const inputRef = useRef(null);
  const autocompleteRef = React.useRef();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [passowrdId, setPasswordId] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [editOpen, setEditOpen] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [eye_icon1, setEye_icon1] = useState(IMAGES.EYE_HIDE);
  const [type1, setType1] = useState("password");
  const [msg1, setMsg1] = useState("reveal password");
  const [eye_icon2, setEye_icon2] = useState(IMAGES.EYE_HIDE);
  const [type2, setType2] = useState("password");
  const [msg2, setMsg2] = useState("reveal password");
  const [eye_icon3, setEye_icon3] = useState(IMAGES.EYE_HIDE);
  const [type3, setType3] = useState("password");
  const [msg3, setMsg3] = useState("reveal password");
  const { state } = useLocation();
  const [fields1, setFields1] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
    email: "",
    name: "",
    longitude: "",
    latitude: "",
    place: "",
    roleTitle: "",
    category: "",
    subCat: "",
    subCatArr: [],
    previousSubCatId: [],
    previousSubCat: [],
    type: "",
  });

  const handleAutocompleteClick = () => {
    autocompleteRef.current.focus();
    setOpenDropdown(false);
  };

  const [categoryList, setCategoryList] = useState([]);
  const [editId, setEditId] = useState([]);
  const [superAdminDetail, setSuperAdminDetail] = useState("");
  const [openOrgDelete, setOpenOrgDelete] = useState(false);

  const handleDeleteOrgOpen = () => {
    document.body.classList.add("modal-open");
    setOpenOrgDelete(true);
  };
  const handleDeleteOrgClose = () => {
    document.body.classList.remove("modal-open");
    setOpenOrgDelete(false);
  };

  const handleOpen = () => {
    document.body.classList.add("modal-open");
    setFields1({
      ...fields1,
      latitude: superAdminDetail.latitude,
      longitude: superAdminDetail.longitude,
      name: superAdminDetail.name,
      place: superAdminDetail.location,
      email: superAdminDetail.email,
      roleTitle: superAdminDetail.designation,
      type: superAdminDetail?.type,
    });
    setOpen(true);
  };
  const [openOrgDeactivate, setOpenOrgDeactivate] = useState(false);
  const handleOrgDeactivateOpen = () => {
    document.body.classList.add("modal-open");
    if (inputRef?.current?.checked === false) {
      return;
    }
    setOpenOrgDeactivate(true);
  };
  const handleOrgDeactivateClose = () => {
    document.body.classList.remove("modal-open");
    setOpenOrgDeactivate(false);
  };

  const handleClose = () => {
    document.body.classList.remove("modal-open");
    setErrors("");
    setOpen(false);
  };

  async function getOrgDeactivateAPI() {
    if (inputRef?.current?.checked === false) {
      return;
    }
    setLoading(true);
    await apiHelper
      .getRequest(`auth/deactivate-account?id=${state.id}`)
      .then((result) => {
        if (result?.code === CONFIG.statusCode) {
          setLoading(false);
          success(result.message);
          getAPI();
          handleOrgDeactivateClose();
        } else {
          error(result.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  async function getDeleteOrgAPI() {
    setLoading(true);
    await apiHelper
      .getRequest(`auth/deleted-account?id=${state.id}`)
      .then((result) => {
        if (result?.code === CONFIG.statusCode) {
          setLoading(false);

          success(result.message);
          handleDeleteOrgClose();
          navigate(ROUTES.EMPLOYEES_LIST);
        } else {
          error(result.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const [subCategory, setSubCategoryData] = useState([]);

  const handleEditOpen = (id, catName, subcatdata) => {
    document.body.classList.add("modal-open");

    setSubCategoryData(subcatdata);
    const selectedNames = subcatdata
      .filter((item) => item.selected !== null)
      .map((item) => item.name);

    const selectedIds = subcatdata
      .filter((item) => item.selected !== null)
      .map((item) => item.id);
    setEditId(id);
    setEditOpen(true);
    setFields1({
      category: catName,
      subCat: "",
      subCatArr: selectedNames,
      previousSubCat: subcatdata?.map((item) => item.name),
      previousSubCatId: selectedIds,
    });
    localStorage.setItem("subCatArr", JSON.stringify(selectedNames));
  };

  const handleEditClose = () => {
    document.body.classList.remove("modal-open");
    setFields1({
      ...fields1,
      category: "",
      subCat: "",
      subCatArr: [],
    });
    setEditOpen(false);
    localStorage.removeItem("subCatArr");
  };

  const handleChangePasswordOpen = (id) => {
    document.body.classList.add("modal-open");

    setPasswordId(id);
    setOpenChangePassword(true);
  };
  const handleChangePasswordClose = () => {
    document.body.classList.remove("modal-open");

    setFields1({
      ...fields1,
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
    });
    setErrors("");
    setOpenChangePassword(false);
  };

  const [errors, setErrors] = useState("");
  const [fields, setFields] = useState({
    email: "",
  });

  const showOldPassword = () => {
    if (type3 === "password") {
      setType3("text");
      setEye_icon3(IMAGES.EYE_SHOW);
      setMsg3("hide password");
    } else {
      setType3("password");
      setEye_icon3(IMAGES.EYE_HIDE);
    }
  };
  const showPassword = () => {
    if (type1 === "password") {
      setType1("text");
      setEye_icon1(IMAGES.EYE_SHOW);
      setMsg1("hide password");
    } else {
      setType1("password");
      setEye_icon1(IMAGES.EYE_HIDE);
    }
  };
  const showConfirmPassword = () => {
    if (type2 === "password") {
      setType2("text");
      setEye_icon2(IMAGES.EYE_SHOW);
      setMsg2("hide password");
    } else {
      setType2("password");
      setEye_icon2(IMAGES.EYE_HIDE);
    }
  };

  const inputRef1 = useRef(null);
  let key = "AIzaSyDFkw6-U8mAQEGTOC77ZYrIojD4isGoNgg";

  const handlePlaceChanged = () => {
    setErrors("");
    const [place] = inputRef1?.current?.getPlaces();

    if (place) {
      setFields1({
        ...fields1,
        place: place.formatted_address,
        latitude: `${place.geometry.location.lat()}`,
        longitude: `${place.geometry.location.lng()}`,
      });
    }
  };
  const handleChange = (e) => {
    setErrors("");

    const name = e.target.name;
    const value = e.target.value;

    if (name === "place") {
      setFields1({
        ...fields1,
        [name]: value,
        latitude: "",
        longitude: "",
      });
    } else {
      setFields1({
        ...fields1,
        [name]: value,
      });
    }
  };
  const handleChange2 = (e) => {
    setErrors("");
    const name = e.target.name;
    const value = e.target.value;
    setFields1({
      ...fields1,
      [name]: value,
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let passwordReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
    const { oldPassword, newPassword1, newPassword2 } = fields1;

    if (oldPassword.trim() == "") {
      formIsValid = false;
      errors["oldPassword"] = ERR_MESSAGE.OLD_PASSWORD_EMPTY;
    } else if (passwordReg.test(oldPassword) === false) {
      formIsValid = false;
      errors["oldPassword"] = ERR_MESSAGE.INVALID_PASSWORD;
    }
    if (newPassword1.trim() == "") {
      formIsValid = false;
      errors["newPassword1"] = ERR_MESSAGE.NEW_PASSWORD_EMPTY;
    } else if (passwordReg.test(newPassword1) === false) {
      formIsValid = false;
      errors["newPassword1"] = ERR_MESSAGE.INVALID_PASSWORD;
    }
    if (newPassword2.trim() == "") {
      formIsValid = false;
      errors["newPassword2"] = ERR_MESSAGE.CONFIRM_PASSWORD_EMPTY;
    } else if (newPassword2.trim() !== newPassword1.trim()) {
      formIsValid = false;
      errors["newPassword2"] = ERR_MESSAGE.NOTMATCHED;
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleValidation2 = () => {
    let errors = {};
    let formIsValid = true;
    const { category, subCatArr } = fields1;
    if (!category || category.trim().length === 0) {
      formIsValid = false;
      errors["category"] = ERR_MESSAGE.CATEGORY_EMPTY;
    }
    // if (!subCatArr || subCatArr.length === 0) {
    //     formIsValid = false;
    //     errors["subCatArr"] = ERR_MESSAGE.OPTIONS_EMPTY
    // }
    setErrors(errors);
    return formIsValid;
  };
  const handleValidationProfile = () => {
    let errors = {};
    let formIsValid = true;
    const emojiRegex =
      /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const { email, name, place, longitude, latitude, roleTitle } = fields1;
    if (!name || name.trim().length === 0) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.EMPTY_NAME;
    } else if (name.length < 3) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_NAME_MIN;
    } else if (name.length > 30) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_NAME_MAX;
    } else if (emojiRegex.test(name)) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_NAME;
    }
    if (!email || email.trim().length === 0) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_EMPTY;
    } else if (reg.test(email.toLowerCase()) === false) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
    }
    if (!place || place.trim().length === 0) {
      formIsValid = false;
      errors["place"] = ERR_MESSAGE.LOCATION_EMPTY;
    } else if (
      !longitude ||
      longitude?.trim().length === 0 ||
      !latitude ||
      latitude?.trim().length === 0
    ) {
      formIsValid = false;
      errors["place"] = ERR_MESSAGE.INVALID_LOCATION;
    } else if (emojiRegex.test(place)) {
      formIsValid = false;
      errors["place"] = ERR_MESSAGE.EMOJI;
    }
    if (!roleTitle || roleTitle.trim().length === 0) {
      formIsValid = false;
      errors["roleTitle"] = ERR_MESSAGE.ROLE_EMPTY;
    }
    setErrors(errors);
    return formIsValid;
  };
  const success = (msg) => {
    toast.success(msg, {
      autoClose: 5000,
    });
  };
  const error = (msg) => {
    toast.success(msg, {
      autoClose: 5000,
    });
  };
  const id = localStorage.getItem("id");
  async function sendInvite(invite_id) {
    let data = JSON.stringify({
      id: invite_id,
      organisation_id: id,
    });
    setLoading(true);
    let result = await apiHelper.postRequest(
      "organization/re-invite-user",
      data
    );
    if (result?.code === CONFIG.statusCode) {
      setLoading(false);
      setInviteModel(true);
      // success(result.message)
      // getAPI()
    } else {
      error(result.message);
      setLoading(false);
    }
  }
  async function getOrgActivateAPI() {
    if (inputRef?.current?.checked === true) {
      return;
    }
    setLoading(true);
    let result = await apiHelper.getRequest(
      `auth/deactivate-account?id=${state.id}`
    );
    if (result?.code === CONFIG.statusCode) {
      setLoading(false);
      success(result.message);
      getAPI();
    } else {
      error(result.message);
      setLoading(false);
    }
  }

  async function postAPI(e) {
    e.preventDefault();
    if (handleValidation()) {
      let data = JSON.stringify({
        oldPassword: fields1.oldPassword,
        newPassword: fields1.newPassword2,
      });
      setLoading(true);
      let result = await apiHelper.postRequest(
        `organization/change-password?id=${passowrdId}`,
        data
      );
      if (result?.code === CONFIG.statusCode) {
        setLoading(false);
        success(result.message);
        handleChangePasswordClose();
      } else {
        error(result.message);
        setLoading(false);
      }
    }
  }

  async function postAPIEditProfile(e) {
    e.preventDefault();
    if (handleValidationProfile()) {
      let data = JSON.stringify({
        name: fields1.name,
        designation: fields1.roleTitle,
        id: superAdminDetail.id,
        location: fields1.place,
        latitude: fields1.latitude,
        email: fields1.email,
        longitude: fields1.longitude,
        type: fields1.type,
      });
      setLoading(true);
      let result = await apiHelper.postRequest("auth/set-your-profile", data);
      if (result?.code === CONFIG.statusCode) {
        setLoading(false);
        success(result.message);
        handleClose();
        getAPI();
      } else {
        error(result.message);
        setLoading(false);
      }
    }
  }

  async function postAPIEditCat(e) {
    e.preventDefault();
    const selectedIds = [];
    const storedSubCatArr = localStorage.getItem("subCatArr");
    const newStore = JSON.parse(storedSubCatArr);
    newStore.forEach((subCat) => {
      const matchingSubCategory = subCategory.find(
        (item) => item.name === subCat
      );
      if (matchingSubCategory) {
        selectedIds.push(matchingSubCategory.id);
      }
    });
    if (handleValidation2() && selectedIds) {
      let data = JSON.stringify({
        changedfrom: fields1.previousSubCatId,
        changedto: selectedIds,
        user_id: state.id,
      });
      setLoading(true);
      let result = await apiHelper.postRequest("auth/update-category", data);
      if (result?.code === CONFIG.statusCode) {
        setLoading(false);
        success(result.message);
        handleEditClose();
        getAPI();
      } else {
        error(result.message);
        setLoading(false);
      }
    }
  }

  async function getAPI() {
    setLoading(true);
    let result = await apiHelper.getRequest(
      "auth/other-profile?id=" + state.id
    );
    if (result?.code === CONFIG.statusCode) {
      setCategoryList(result?.body.usercatdata);
      setSuperAdminDetail(result?.body);
      if (editId) {
        const matchedCategory = result.body.usercatdata.find(
          (category) => category.id === editId
        );
        if (matchedCategory) {
          const { name, osubcatdata } = matchedCategory;
          const selectedIds = osubcatdata
            .filter((item) => item.selected !== null)
            .map((item) => item.id);
          const storedSubCatArr = localStorage.getItem("subCatArr");
          const newStore = JSON.parse(storedSubCatArr);
          setFields1({
            category: name,
            subCat: "",
            subCatArr: newStore,
            previousSubCat: osubcatdata?.map((item) => item.name),
            previousSubCatId: selectedIds,
          });
          setSubCategoryData(osubcatdata);
        }
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    getRoleAPI();
    getAPI();
  }, []);

  async function textApi(value) {
    let data = {
      name: value,
      cat_id: editId,
    };
    setLoading(true);
    try {
      let result = await apiHelper.postRequest(
        "auth/create-subcategory",
        JSON.stringify(data)
      );
      if (result?.code === CONFIG.statusCode) {
        const newName = result.body.name;
        const storedArrayString = localStorage.getItem("subCatArr");
        const storedArray = JSON.parse(storedArrayString);
        const newValues = [...storedArray, newName];
        localStorage.setItem("subCatArr", JSON.stringify(newValues));
        getAPI();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const handleKeyDown = (event) => {
    const { key, target } = event;
    if (key === "Enter" || key === ",") {
      event.preventDefault();
      const value = target.value.trim();
      if (!fields1?.previousSubCat.includes(value)) {
        textApi(value);
        setOpenDropdown(true);
      } else {
        const storedSubCatArr = JSON.parse(localStorage.getItem("subCatArr"));
        const newValues = [...storedSubCatArr, value];
        if (!fields1?.previousSubCat.includes(value)) {
          setFields1({
            ...fields1,
            subCatArr: newValues,
          });
          localStorage.setItem("subCatArr", JSON.stringify(newValues));
        }
        setOpenDropdown(true);
      }
    }
  };

  const [roleList, setRoleList] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(roleList);

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const handleInputChange = (event, newValue) => {
    setErrors("");
    const filteredRoles = roleList?.filter((role) =>
      role.toLowerCase().includes(newValue.toLowerCase())
    );
    setFilteredOptions(filteredRoles);
    setFields1((prevFields) => ({
      ...prevFields,
      roleTitle: newValue,
    }));
  };

  const handleSelectionChange = (event, newValue) => {
    setErrors("");
    if (event.key === "Enter" || event.code === "NumpadEnter") {
      setOpenDropdown(false);
    }
    setFields1((prevFields) => ({
      ...prevFields,
      roleTitle: newValue,
    }));
    setOpenDropdown(false);
  };

  const handleDropdownChange2 = (e) => {
    setErrors("");
    const name = e.target.name;
    const value = e.target.value;
    setFields1((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
    if (e.target.value === "" || roleList.includes(value)) {
      setOpenDropdown(false);
    } else {
      setOpenDropdown(true);
    }
  };

  async function getRoleAPI() {
    setLoading(true);
    let result = await apiHelper.getRequest(`organization/role-list`);
    if (result?.code === CONFIG.statusCode) {
      setRoleList(result?.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>{titles.employeeProfile}</title>
      </Helmet>
      <div className="loader">{isLoading && <ReactLoader />}</div>
      <section className="pt-5">
        <div className="container">
          <div className="row align-items-center pb-4">
            <div className="col-12 col-lg-4">
              <nav>
                <ul
                  style={{ cursor: "pointer" }}
                  className="d-flex gap-2 align-items-center mb-0"
                  onClick={() => navigate(ROUTES.EMPLOYEES_LIST)}
                >
                  <li>
                    <img
                      src={IMAGES.BACK_ARROW}
                      alt=""
                    />
                  </li>
                  <li>
                    <a>{superAdminDetail?.name?.replace(/-/g, " ")}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row align-items-center text-end text-md-start">
                <div className="col-12">
                  <div className="switch-toggle-head text-end">
                    <div
                      style={{ cursor: "pointer", zIndex: "1" }}
                      className="d-flex justify-content-end align-items-center switch-delete-acc position-relative"
                    >
                      <div className="delete-acc-link text-end me-1 me-sm-2">
                        <a onClick={handleDeleteOrgOpen}>Delete Account</a>
                      </div>
                      <label className="switch">
                        <input
                          ref={inputRef}
                          className="switch-input"
                          type="checkbox"
                          checked={
                            superAdminDetail?.is_active === 1 ? true : false
                          }
                        />
                        {superAdminDetail?.invite_status === 0 ? (
                          <>
                            <div className="switch-button switch-button-grey">
                              <span
                                className="switch-button-left"
                                style={{ cursor: "not-allowed" }}
                              >
                                Deactivate
                              </span>
                              <span
                                className="switch-button-right"
                                style={{ cursor: "not-allowed" }}
                              >
                                Activate
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="switch-button ">
                              <span
                                className="switch-button-left"
                                style={{ cursor: "pointer" }}
                                onClick={handleOrgDeactivateOpen}
                              >
                                {superAdminDetail?.is_active === 2
                                  ? "Deactivated"
                                  : "Deactivate"}
                              </span>
                              <span
                                className="switch-button-right"
                                style={{ cursor: "pointer" }}
                                onClick={getOrgActivateAPI}
                              >
                                {superAdminDetail?.is_active === 1
                                  ? "Activated"
                                  : "Activate"}
                              </span>
                            </div>
                          </>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-3">
              <div className="profile-heading">
                <h2>Member Profile</h2>
              </div>
              <div className="company-info back-shadow">
                <div className="p-4">
                  <div className="company-section-sub-head d-flex justify-content-between py-3 ps-xxl-4 pe-xxl-0 align-items-center">
                    <h2 className="company-info-head mb-0">
                      {superAdminDetail?.name?.replace(/-/g, " ")}
                      {/* <p className="user-professtion"> {superAdminDetail.designation == null ? " Profession" : superAdminDetail.designation}</p> */}
                    </h2>
                    <img
                      onClick={handleOpen}
                      style={{ cursor: "pointer" }}
                      src={IMAGES.EDIT_ICON}
                      alt="edit-icon"
                      className="img-fluid"
                    />
                  </div>
                  <ul className="sub-company-list mb-2">
                    <li className="sub-company-list-item">
                      <label className="sq-radio">
                        <input
                          type="checkbox"
                          checked="checked"
                          name="radio"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <Stack
                        className="label-profile"
                        direction={{
                          xs: "column",
                          sm: "row",
                        }}
                        spacing={{
                          xs: 0,
                          sm: 2,
                        }}
                      >
                        <Typography
                          className="pe-1"
                          sx={{ minWidth: "fit-content" }}
                        >
                          Email:
                        </Typography>
                        <Link
                          href={`mailto:${superAdminDetail.email}`}
                          underline="hover"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordWrap: "nowrap",
                            whiteSpace: "nowrap",
                            maxWidth: "fit-content",
                            color: "inherit",
                            textDecorationColor: "inherit",
                            "&:hover": {
                              fontWeight: 700,
                              color: "inherit",
                            },
                          }}
                        >
                          <Typography
                            className="fw-900"
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              wordWrap: "nowrap",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Tooltip
                              title={superAdminDetail.email}
                              variant="solid"
                              placement="bottom-start"
                              size="sm"
                            >
                              {superAdminDetail.email}
                            </Tooltip>
                          </Typography>
                        </Link>
                      </Stack>
                    </li>
                    <li className="sub-company-list-item">
                      <label className="sq-radio">
                        <input
                          type="checkbox"
                          checked="checked"
                          name="radio"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <div className="label-profile">
                        <p className="pe-1 label-head">Location :</p>
                        <p className="fw-900 one-line-text text-cus-align">
                          {superAdminDetail.location}
                        </p>
                      </div>
                    </li>
                    <li className="sub-company-list-item">
                      <label className="sq-radio">
                        <input
                          type="checkbox"
                          checked="checked"
                          name="radio"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <div className="label-profile">
                        <p className="pe-1 label-head">Role :</p>
                        <p className="fw-900 one-line-text text-cus-align">
                          {superAdminDetail.designation}
                        </p>
                      </div>
                    </li>
                    <li className="sub-company-list-item">
                      <label className="sq-radio">
                        <input
                          type="checkbox"
                          checked="checked"
                          name="radio"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <div className="label-profile">
                        <p className="pe-1 label-head">User Type :</p>
                        <p className="fw-900 one-line-text text-cus-align">
                          {superAdminDetail.role_name}
                        </p>
                      </div>
                    </li>
                  </ul>
                  <div
                    className="mx-auto text-center"
                    style={{ cursor: "pointer" }}
                  >
                    {superAdminDetail.is_active == 1 &&
                    superAdminDetail.invite_status === 1 &&
                    superAdminDetail.is_profile === 0 ? (
                      <div
                        onClick={() => sendInvite(superAdminDetail.id)}
                        className="company-info-btns position-relative mt-4"
                      >
                        <button
                          type="button"
                          style={{ background: "#F949B2" }}
                          className="yellow-btn add-category-btn d-flex align-items-center yellow-change-btn "
                        >
                          Invite to haptapt{" "}
                          <img
                            src={IMAGES.ARROW_GREY}
                            alt=""
                            className="img-fluid ms-2"
                          />
                        </button>
                      </div>
                    ) : (
                      <div
                        disabled
                        className="company-info-btns position-relative mt-4"
                      >
                        <button
                          type="button"
                          style={{ background: "#4F4A4A" }}
                          className="yellow-btn add-category-btn d-flex align-items-center yellow-change-btn "
                        >
                          Invite to haptapt{" "}
                          <img
                            src={IMAGES.ARROW_GREY}
                            alt=""
                            className="img-fluid ms-2"
                          />
                        </button>
                      </div>
                    )}
                  </div>
                  {superAdminDetail?.invite_status === 0 ? (
                    <>
                      <div
                        className="mx-auto text-center"
                        style={{ cursor: "not-allowed" }}
                      >
                        <div className="company-info-btns position-relative mt-4">
                          <button
                            type="button"
                            className="yellow-btn add-category-btn d-flex align-items-center yellow-change-btn grey-change-btn"
                          >
                            Reset password{" "}
                            <img
                              src={IMAGES.ARROW_GREY}
                              alt=""
                              className="img-fluid ms-2"
                            />
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="mx-auto text-center"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          onClick={() =>
                            handleChangePasswordOpen(superAdminDetail.id)
                          }
                          className="company-info-btns position-relative mt-4"
                        >
                          <button
                            type="button"
                            className="yellow-btn add-category-btn d-flex align-items-center yellow-change-btn "
                          >
                            Reset password{" "}
                            <img
                              src={IMAGES.ARROW_GREY}
                              alt=""
                              className="img-fluid ms-2"
                            />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-9 pt-4 pt-xl-0">
              <div className="row">
                <div className="col-12">
                  <div className="category-list">
                    <ul>
                      {categoryList.map((item) => {
                        return (
                          <li key={item.id}>
                            <div className="list-category-main d-flex justify-content-between align-items-center">
                              <div className="list-category-left">
                                <div className="list-category-head">
                                  <p>{item.name}</p>
                                </div>
                                <div className="list-content mt-3">
                                  {item.osubcatdata &&
                                    item.osubcatdata.length > 0 &&
                                    item.osubcatdata
                                      .filter(
                                        (subcat) => subcat.selected !== null
                                      )
                                      .map((subcat) => (
                                        <p
                                          key={subcat.id}
                                          className="list-content-item mb-2 mb-xxl-3"
                                        >
                                          {subcat.name}
                                        </p>
                                      ))}
                                </div>
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                className="list-category-right"
                              >
                                <img
                                  onClick={() =>
                                    handleEditOpen(
                                      item.id,
                                      item.name,
                                      item.osubcatdata
                                    )
                                  }
                                  src={IMAGES.EDIT_ICON}
                                  alt="edit-icon"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <InviteSent
        invteModel={invteModel}
        setInviteModel={setInviteModel}
        getAPI={getAPI}
      />

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h1>edit profile</h1>
              </div>
              <form onSubmit={postAPIEditProfile}>
                <div className="mb-5 position-relative mx-5">
                  <TextField
                    fullWidth
                    label={state?.id ? "name" : "Your name"}
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": { color: "#231f20;" },
                    }}
                    name="name"
                    onChange={handleChange}
                    value={fields1.name}
                  />
                  {errors.name && (
                    <span className="err_msg">{errors.name}</span>
                  )}
                </div>
                <div className="mb-5 position-relative mx-5">
                  <label className="form-label email-label mb-0 email-input">
                    Email address
                  </label>
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    error={errors.email}
                    value={fields1.email}
                    className="form-control comm-radius comm-input"
                    // (item.is_active == 1 && item.invite_status === 1 && item.is_profile === 0)
                    disabled={
                      (superAdminDetail.is_active == 1 &&
                        superAdminDetail.invite_status == 1 &&
                        superAdminDetail.is_profile == 0) ||
                      superAdminDetail.invite_status === 0
                        ? false
                        : true
                    }
                  />
                  {errors.email && (
                    <span className="err_msg">{errors.email}</span>
                  )}
                </div>
                <div className="mb-5 position-relative mx-5">
                  <LoadScript
                    googleMapsApiKey={key}
                    libraries={libraries}
                  >
                    <StandaloneSearchBox
                      ref={inputRef1}
                      onLoad={(ref) => (inputRef1.current = ref)}
                      onPlacesChanged={handlePlaceChanged}
                    >
                      <TextField
                        fullWidth
                        label="Location"
                        variant="outlined"
                        sx={{
                          "& fieldset": {
                            border: "2px solid #231f20",
                            borderRadius: "30px",
                          },
                          "& .MuiInputLabel-shrink": {
                            color: "#231f20;",
                          },
                        }}
                        name="place"
                        onChange={handleChange}
                        value={fields1.place}
                        placeholder="Enter location"
                      />
                    </StandaloneSearchBox>
                  </LoadScript>
                  {errors?.place && (
                    <span className="err_msg">{errors.place}</span>
                  )}
                </div>
                <div className="mb-5 my-4 text-end role-custome mx-5">
                  <Autocomplete
                    fullWidth
                    options={filteredOptions}
                    onBlur={closeDropdown}
                    getOptionLabel={(option) => option}
                    value={fields1?.roleTitle}
                    onInputChange={handleInputChange}
                    onChange={handleSelectionChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.code === "NumpadEnter") {
                        setOpenDropdown(false);
                      }
                    }}
                    open={openDropdown}
                    disableClearable
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        onChange={(e) => handleDropdownChange2(e)}
                        label="Role/Title"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <React.Fragment></React.Fragment>,
                        }}
                        onFocusCapture={() => {
                          setOpenDropdown(false);
                        }}
                        sx={{
                          "& fieldset": {
                            border: "2px solid #231f20",
                            borderRadius: "30px",
                          },
                          "& .MuiInputLabel-shrink": {
                            color: "#231f20;",
                          },
                        }}
                      />
                    )}
                  />

                  {/* <TextField fullWidth label=" Role/Title" variant="outlined"
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="roleTitle" onChange={handleChange} value={fields1.roleTitle} /> */}
                  {errors.roleTitle && (
                    <span className="err_msg">{errors.roleTitle}</span>
                  )}
                </div>

                <div className="mb-5 position-relative mx-5">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      User Type
                    </InputLabel>
                    <Select
                      fullWidth
                      value={fields1?.type}
                      name="type"
                      label="Type"
                      variant="outlined"
                      onChange={handleChange}
                      sx={{
                        "& fieldset": {
                          border: "2px solid #231f20",
                          borderRadius: "30px",
                        },
                        "& .MuiInputLabel-shrink": {
                          color: "#231f20;",
                        },
                      }}
                    >
                      <MenuItem value="2">Organization Admin</MenuItem>
                      <MenuItem value="1">Member</MenuItem>
                    </Select>
                  </FormControl>
                  {errors.type && (
                    <span className="err_msg">{errors.type}</span>
                  )}
                </div>
                <div className="mb-5 text-center position-relative submit-head">
                  <button
                    type="submit"
                    className="yellow-btn d-flex align-items-center"
                  >
                    Save{" "}
                    <img
                      src={IMAGES.ARROW_GREY}
                      alt=""
                      className="img-fluid ms-2"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openChangePassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleChangePasswordClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h1>change password</h1>
              </div>
              <form onSubmit={postAPI}>
                <div className="editbottom position-relative  mx-4 mx-sm-5 forgot-input">
                  <TextField
                    type={type3}
                    fullWidth
                    label="Old password"
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": { color: "#231f20;" },
                    }}
                    name="oldPassword"
                    onChange={handleChange2}
                    value={fields.oldPassword}
                  />

                  <img
                    src={eye_icon3}
                    alt=""
                    onClick={showOldPassword}
                    className="hiden-eye"
                    title={msg3}
                  />
                  {errors.oldPassword && (
                    <span className="err_msg">{errors.oldPassword}</span>
                  )}
                </div>
                <div className="editbottom position-relative  mx-4 mx-sm-5 forgot-input">
                  <TextField
                    type={type1}
                    fullWidth
                    label="new password"
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": { color: "#231f20;" },
                    }}
                    name="newPassword1"
                    onChange={handleChange2}
                    value={fields.newPassword1}
                  />

                  <img
                    src={eye_icon1}
                    alt=""
                    onClick={showPassword}
                    className="hiden-eye"
                    title={msg1}
                  />
                  {errors.newPassword1 && (
                    <span className="err_msg">{errors.newPassword1}</span>
                  )}
                </div>
                <div className="editbottom position-relative  mx-4 mx-sm-5 forgot-input">
                  <TextField
                    type={type2}
                    fullWidth
                    label="new password"
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": { color: "#231f20;" },
                    }}
                    name="newPassword2"
                    onChange={handleChange2}
                    value={fields.newPassword2}
                  />

                  <img
                    src={eye_icon2}
                    alt=""
                    onClick={showConfirmPassword}
                    className="hiden-eye"
                    title={msg2}
                  />
                  {errors.newPassword2 && (
                    <span className="err_msg">{errors.newPassword2}</span>
                  )}
                </div>
                <div className="editbottom text-center position-relative submit-head">
                  <button
                    type="submit"
                    className="yellow-btn d-flex align-items-center"
                  >
                    Save{" "}
                    <img
                      src={IMAGES.ARROW_GREY}
                      alt=""
                      className="img-fluid ms-2"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openOrgDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleDeleteOrgClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h2>delete account</h2>
              </div>
              <div className="mb-4 position-relative text-center">
                <p>
                  Are you certain that you want to proceed with deleting the
                  account from the platform?
                </p>
              </div>
              <div
                className="mb-5 text-center position-relative submit-head"
                onClick={getDeleteOrgAPI}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  Delete{" "}
                  <img
                    src={IMAGES.ARROW_GREY}
                    alt=""
                    className="img-fluid ms-2"
                  />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={editOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleEditClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h1>Edit Category</h1>
              </div>
              <div className="mb-4  position-relative px-3 text-center">
                <p>
                  Please modify the category name and its associated options for
                  input on the platform.{" "}
                </p>
              </div>
              <form onSubmit={postAPIEditCat}>
                <div className="mb-3 mb-lg-5 position-relative mx-5">
                  <TextField
                    fullWidth
                    label="Enter category name"
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": { color: "#231f20;" },
                    }}
                    name="category"
                    value={fields1.category}
                    aria-readonly
                  />
                  {errors.category && (
                    <span className="err_msg">{errors.category}</span>
                  )}
                </div>
                <div className="mb-3 mb-lg-5 position-relative mx-5">
                  <Autocomplete
                    ref={autocompleteRef}
                    multiple
                    id="checkboxes-tags-demo"
                    options={fields1.previousSubCat}
                    disableCloseOnSelect
                    value={fields1.subCatArr}
                    getOptionLabel={(option) => option}
                    open={openDropdown}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Options"
                        onKeyDown={handleKeyDown}
                        onFocus={() => setOpenDropdown(true)}
                        onBlur={() => setOpenDropdown(false)}
                        onChange={(e) => {
                          const value = e.target.value;
                          setOpenDropdown(
                            fields1.subCatArr.some((item) => item === value)
                          );
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setErrors("");
                      setFields1({
                        ...fields1,
                        subCatArr: newValue,
                      });

                      setOpenDropdown(true);
                      localStorage.setItem(
                        "subCatArr",
                        JSON.stringify(newValue)
                      );
                    }}
                    onClick={handleAutocompleteClick}
                    className="custom-autocomplete"
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                  {errors.subCatArr && (
                    <span className="err_msg">{errors.subCatArr}</span>
                  )}
                </div>

                <div className="text-center mb-5 position-relative submit-head">
                  <button
                    type="submit"
                    className="yellow-btn d-flex align-items-center"
                  >
                    Update{" "}
                    <img
                      src={IMAGES.ARROW_GREY}
                      alt=""
                      className="img-fluid ms-2"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openOrgDeactivate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleOrgDeactivateClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h2>Deactivate account</h2>
              </div>
              <div className="mb-4  position-relative text-center">
                <p>
                  Are you certain that you want to proceed with deleting the
                  account from the platform?
                </p>
              </div>
              <div
                className="mb-5 text-center position-relative submit-head"
                onClick={getOrgDeactivateAPI}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  Deactivate{" "}
                  <img
                    src={IMAGES.ARROW_GREY}
                    alt=""
                    className="img-fluid ms-2"
                  />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer rtl />
    </>
  );
};
export default EmployeeProfile;
