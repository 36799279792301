const CONFIG = {
	base_url: process.env.REACT_APP_API_URL || "https://api-v1.haptapt.com/api/v1/",
    statusCode: 200,
    unAuthorized: 401,
    noPermission: 403,
    internalServer: 500,
    internalServerError: 501,
    TOKEN: "token",
    OTP_TOKEN: "otpToken",
    REFRESH_TOKEN: "refeshtoken",
    LOGIN_TIME: "loginTime",
    TOKEN_EXPIRETIME: "accessTokenExpireTime",
    REFRESHTOKEN_EXPIRETIME: "refeshTokenExpireTime",
};

export default CONFIG;
