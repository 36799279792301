import React, { useState, useRef, useEffect } from 'react'
import IMAGES from '../../Middleware/images'
import ReactLoader from '../../ControlComponents/react-loader';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import "../../Assets/Styles/search-database.css"
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Middleware/routes';
import ERR_MESSAGE from '../../Helper/error-helper';
import { Helmet } from 'react-helmet';
import titles from '../../Helper/title';
import { ToastContainer, toast } from "react-toastify";
import apiHelper from '../../Helper/api-helper';
import CONFIG from "../../Helper/config";
import LocationSearch from '../../Components/location-autocomplete';
import RoleInput from '../../Components/roleInput';
import CategoryComponent from '../../Components/categoryComponent';
function SearchEmployeeDatabase() {
    const navigate = useNavigate();
    const [selectedField, setSelectedField] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessage1, setErrorMessage1] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [labelObj, setLabelObj] = useState({
        role: "Role: (i.e., Account Manager)",
        location: "Location: (i.e., New York)",
        experience: "Experience: (i.e., clients and categories)",
        speciality: "Specialty: (i.e., content strategy, analytics)",
        interests: "Interests outside of work (i.e., Pickleball, Reality TV)",
    });
    const [categoryList, setCategoryList] = useState([])
    const [errors, setErrors] = useState("");

    const [fields, setFields] = useState({
        role: "",
        roleArr: [],
        location: "",
        placesArr: [],
        latitude: "",
        longitude: "",

    });


    const saveValuesToLocalStorage = (values, storageKey) => {
        localStorage.setItem(storageKey, JSON.stringify(values));
    };

    const organizationSuccess = (msg) => {
        toast.success(msg, {
            autoClose: 5000,
        });
    };

    let message = localStorage.getItem("message");
    useEffect(() => {
        if (message) {
            organizationSuccess(message);
            localStorage.removeItem("message");
        }
        return () => {
            message = null;
        };
    }, []);


    const handleValidation = () => {
        let categoryNames = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes('category_') && key.endsWith('_values')) {
                const storedValues = JSON.parse(localStorage.getItem(key)) || [];
                const formattedValues = storedValues.map(value => value.name);
                categoryNames.push(...formattedValues);
            }
        }
        let canSubmit = false;
        const { longitude, latitude, place } = fields
        if (fields?.roleArr?.length > 0 || categoryNames?.length > 0 || fields?.placesArr?.length > 0 || place?.length > 0)
            canSubmit = true;
        else
            setErrorMessage(ERR_MESSAGE.ENTER_OPTION)
        if (place?.length > 0 && (longitude === '' || latitude === '')) {
            canSubmit = false;
            setErrorMessage1(ERR_MESSAGE.INVALID_LOCATION);
        }
        return canSubmit;
    }
    const handleOnSubmit = (e) => {
        e.preventDefault();
        let searchArray = [];
        let categoryNames = [];
        let categoryIds = [];
        if (handleValidation()) {
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key.includes('category_') && key.endsWith('_values')) {
                    const storedValues = JSON.parse(localStorage.getItem(key)) || [];
                    const formattedNames = storedValues.map(value => value.name);
                    categoryNames.push(...formattedNames);
                    const formattedIds = storedValues.filter(item => item.id != 'user_1').map(item => item.id);
                    categoryIds.push(...formattedIds);
                }
            }

            if (fields.placesArr.length === 0) {
                searchArray = [
                    ...fields.roleArr.map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()),
                    ...categoryNames
                ];
            } else {
                searchArray = [
                    ...fields.roleArr.map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()),
                    ...fields?.placesArr,
                    ...categoryNames
                ];
            }
            navigate(ROUTES.SEARCH_RESULT, {
                state: {
                    searchArray: searchArray,
                    roleArray: fields?.roleArr,
                    placesArr: fields?.placesArr,
                    latitude: fields?.latitude,
                    longitude: fields?.longitude,
                    selectedIds: categoryIds

                }
            });
        } else {

        }
    };
    async function getMyDetailAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("auth/get-my-detail")
        if (result?.code === CONFIG.statusCode) {
            getCategoryAPI(result.body.email)
            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }
    async function getCategoryAPI(email) {
        setLoading(true)
        let result = await apiHelper.getRequest("auth/category-list?email=" + email)
        if (result?.code === CONFIG.statusCode) {
            setCategoryList(result?.body)
            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }

    async function getAPI() {
        setLoading(true);
        let result = await apiHelper.getRequest(`organization/role-list`);
        if (result?.code === CONFIG.statusCode) {
            setRoleList(result?.body);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAPI()
        getMyDetailAPI()
    }, [])


    return (
        <>
            <Helmet>
                <title>{titles.searchEmployee}</title>
            </Helmet>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <section className="category-step-two">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="category-experience-step text-center">
                                <h2 className="my-2">who or what are you looking for today?</h2>
                                <p>You can search using just one form field.</p>
                                <div className="col-lg-6 mx-auto">
                                    <form
                                        onSubmit={handleOnSubmit}
                                    >

                                        <div className="my-4 text-end">
                                            <RoleInput setSelectedField={setSelectedField} setErrorMessage={setErrorMessage} setLabelObj={setLabelObj} labelObj={labelObj} setErrors={setErrors} fields={fields} setFields={setFields} roleList={roleList} />


                                        </div>
                                        <div className="my-4 text-end">

                                            <LocationSearch setErrorMessage1={setErrorMessage1} setLabelObj={setLabelObj} labelObj={labelObj} setSelectedField={setSelectedField} setErrorMessage={setErrorMessage} selectedField={selectedField} fields={fields} setFields={setFields} />
                                            {errorMessage1 && <span className="err_msg">{errorMessage1}</span>}
                                        </div>


                                        {
                                            categoryList.map((item) => {
                                                return (
                                                    <div className="mt-4 mb-2 text-end" key={item.id}>
                                                        <CategoryComponent
                                                            subCategoryList={item}
                                                            setErrorMessage={setErrorMessage}
                                                            storageKey={`category_${item.id}_values`}
                                                            saveValuesToLocalStorage={(values) => saveValuesToLocalStorage(values, `category_${item.id}_values`)}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }

                                        {errorMessage && <span className="err_msg">{errorMessage}</span>}
                                        <div className="text-center position-relative submit-head d-flex align-items-center gap-4 step-one-bottom justify-content-center">
                                            <button type="submit" className="proceed-yellow-btn position-relative d-flex align-items-center mt-3">Find Them <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer rtl />

            </section>


        </>
    )
}

export default SearchEmployeeDatabase