import { Modal, Box } from "@mui/material";

const HaptaptModal = ({ children, isOpen, handleClose, boxStyle }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 1,
    outline: 0,
    "max-width": "676px",
    borderRadius: "20px",
    "box-shadow":
      "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
    border: "1px solid #6ED7EF",
    maxHeight: "90%",
    // overflowY: "scroll"
    };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
      keepMounted
    >
      <Box sx={{
        ...style,
        ...boxStyle
      }}>{children}</Box>
    </Modal>
  );
};

export default HaptaptModal;
